import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { MapCharAmchar } from 'utils/AmChars';
import CardLight from '../../CardLight';

type MapCharProps = {
  onOpenMapModal: ( { params }:{ params:any } ) => void
  dataObjs: any[] | undefined ;
};
const MapChar = ( {
  dataObjs, onOpenMapModal,
}: MapCharProps ) => {
  const { t } = useTranslation();

  useEffect( () => {
    if ( process.env.NODE_ENV !== 'test' && !!dataObjs ) MapCharAmchar( dataObjs, onOpenMapModal );
  }, [dataObjs] );

  return (
    <CardLight title={t( 'dashboard.map' )} isLoading={!dataObjs}>
      <div id="map" style={{ height: 280 }} />
      <hr />
      <Row>
        <Col xs={1}><div className="chart-legend" style={{ backgroundColor: '#166B8E' }} /></Col>
        <Col xs="auto">
          <span className="text--medium">{t( 'Mis inversiones' )}</span>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={1}><div className="chart-legend" style={{ backgroundColor: '#3C3C3B' }} /></Col>
        <Col xs="auto">
          <span className="text--medium">{t( 'Otras inversiones' )}</span>
        </Col>
      </Row>
    </CardLight>
  );
};

export default MapChar;
