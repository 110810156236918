import api from './index';

export default {
  getProfile() {
    return api.get( 'api/profile' );
  },
  putChangePassword( data:any ) {
    return api.put( 'api/profile/changepassword', data );
  },
  getContactoAllBackOffice( params:any, config:any = {} ) {
    return api.get( 'api/contacto/GetAllBackOffice', params, config );
  },
  getAllUsersBackOffice( params:any ) {
    return api.get( 'api/profile/GetAllUsersBackOffice', params );
  },
  getAllByCuentaDeInversionBackOffice( params:any, config:any = {} ) {
    return api.get( 'api/profile/GetAllByCuentaDeInversionBackOffice', params, config );
  },
  putAddUserFromContactBackOffice( params:any ) {
    return api.put( `api/profile/AddUserFromContactBackOffice/${params.contactId}` );
  },
  putDiscardedContactBackOffice( params:any ) {
    return api.put( `api/contacto/DiscardedContactBackOffice/${params.contactId}` );
  },
  putChangeUserStatusBackOffice( params:any ) {
    return api.put( `api/profile/ChangeUserStatusBackOffice/${params.userId}/${params.isActive}` );
  },
  putUploadImage( params:any ) {
    return api.put( 'api/profile/upload/changeimage', params );
  },
};
