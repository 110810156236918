import React, { useEffect, useState } from 'react';
import {
  Button, Col, Modal, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProfileService from 'services/Profile';
import useSubmit from 'hooks/useSubmit';
import SuccessMessage from '../SuccessMessage';
import { MODAL_ACTIVE_USER, MODAL_ADD_USER, MODAL_DISABLED_USER } from '../../../constants/utils';

type CreateUserModalProps = {
  show: boolean,
  contact: { id:number; fullName:string; action: string },
  onClose: () => void,
  reloadData: () => void,
};

const CreateUserModal = ( {
  show, onClose, contact, reloadData,
}: CreateUserModalProps ) => {
  const { t } = useTranslation();
  const [discardedContact, setDiscardedContact] = useState( false );
  const [formSubmitted, setFormSubmitted] = useState( false );
  const [formSubmittedError, setFormSubmittedError] = useState( '' );

  const submitForm = useSubmit( {
    promise: ( formData ) => {
      if ( contact.action === MODAL_ACTIVE_USER || contact.action === MODAL_DISABLED_USER ) {
        return ProfileService.putChangeUserStatusBackOffice( formData );
      }
      if ( formData.discardedContact ) {
        return ProfileService.putDiscardedContactBackOffice( formData );
      }
      return ProfileService.putAddUserFromContactBackOffice( formData );
    },
    callbackError: ( errors ) => {
      setFormSubmitted( true );
      setFormSubmittedError( errors );
    },
    callback: ( ) => {
      setFormSubmitted( true );
      reloadData();
    },
    showAlert: false,
    deps: [contact],
  } );

  useEffect( () => {
    if ( !show ) setFormSubmitted( false );
  }, [show] );

  let description = '¿Qué desea hacer con el inversor ';
  let title = t( 'admin.common.add' );
  let formSubmittedDescripcion = t( 'addUserSuccess' );
  if ( contact.action === MODAL_ACTIVE_USER ) {
    title = t( 'admin.common.disabled' );
    description = '¿Desea bloquear el inicio de sesion del inversor ';
    formSubmittedDescripcion = t( 'changeUserStatusSuccess' );
  } else if ( contact.action === MODAL_DISABLED_USER ) {
    title = t( 'admin.common.active' );
    description = '¿Desea desbloquear el inicio de sesion del inversor ';
    formSubmittedDescripcion = t( 'changeUserStatusSuccess' );
  } else if ( discardedContact ) {
    formSubmittedDescripcion = t( 'changeUserStatusDisabled' );
  }

  return (
    <Modal
      show={show}
      className="modal-carousel"
      centered
      onHide={onClose}
    >
      <Modal.Header className="pb-0" closeButton />
      <Modal.Body className="pt-0">
        <Row>
          <Col md={11} className="m-auto">
            <h3 className="text-center mb-4">{title}</h3>
            {formSubmitted
              ? (
                <SuccessMessage
                  description={!formSubmittedError ? formSubmittedDescripcion : formSubmittedError}
                  onClose={onClose}
                  success={!formSubmittedError}
                />
              )
              : (
                <div className="text-center">
                  <p className="text--sm">
                    {description}
                    <span className="text--bold">
                      {' '}
                      {contact.fullName}
                      {' '}
                    </span>
                    ?
                  </p>

                  {contact.action === MODAL_ADD_USER && (
                  <Row>
                    <Col className="m-auto mt-3">
                      <Button
                        variant="primary"
                        className="btn--full-width"
                        onClick={() => {
                          setDiscardedContact( false );
                          submitForm( { contactId: contact.id } );
                        }}
                      >
                        {t( 'admin.common.add' )}
                      </Button>
                    </Col>
                    <Col className="m-auto mt-3">
                      <Button
                        variant="danger"
                        className="btn--full-width"
                        onClick={() => {
                          setDiscardedContact( true );
                          submitForm( { contactId: contact.id, discardedContact: true } );
                        }}
                      >
                        {t( 'descartar' )}
                      </Button>
                    </Col>
                  </Row>
                  )}
                  {( contact.action === MODAL_ACTIVE_USER
                      || contact.action === MODAL_DISABLED_USER ) && (
                      <Row>
                        <Col className="m-auto mt-3">
                          <Button variant="outline-primary" className="btn--full-width" onClick={onClose}>
                            {t( 'cancel' )}
                          </Button>
                        </Col>
                        {contact.action === MODAL_ACTIVE_USER && (
                        <Col className="m-auto mt-3">
                          <Button variant="danger" className="btn--full-width" onClick={() => submitForm( { userId: contact.id, isActive: false } )}>
                            {t( 'block' )}
                          </Button>
                        </Col>
                        )}
                        {contact.action === MODAL_DISABLED_USER && (
                        <Col className="m-auto mt-3">
                          <Button className="btn--full-width" onClick={() => submitForm( { userId: contact.id, isActive: true } )}>
                            {t( 'unblock' )}
                          </Button>
                        </Col>
                        )}
                      </Row>
                  )}
                </div>
              )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserModal;
