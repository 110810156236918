export const DASHBOARD = '/';
export const PROJECTS = '/projects';
export const FUNDS = '/investment-vehicle';
export const HISTORIC = '/historic';
export const NEWS = '/news';
export const NEWS_DETAIL = '/news-detail';
export const PROFILE = '/profile';
export const ACTIVE_PROJECTS = '/active-projects';

export const ADMIN_ACCOUNTS = '/admin';
export const ADMIN_PROFILE = '/admin/profile';
export const ADMIN_ACCOUNT_PROJECTS = '/admin/accounts/:id/projects/:type';
export const ADMIN_ACCOUNT_INVESTORS = '/admin/accounts/:id/investors/:type';
export const ADMIN_INVESTORS = '/admin/investors';
export const ADMIN_INVESTOR_ACCOUNTS = '/admin/investors/:id/accounts/:type';
export const ADMIN_INVESTOR_PROJECTS = '/admin/investors/:id/projects/:type';
export const ADMIN_INVESTOR_CHANGE_PASSWORD = '/admin/investors/:id/change-password/:type';
export const ADMIN_PROJECTS = '/admin/projects';
export const ADMIN_PROJECT_ACCOUNTS = '/admin/projects/:id/accounts/:type';
export const ADMIN_PROJECT_INVESTORS = '/admin/projects/:id/investors/:type';
