import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import validateForm from 'utils/Validator';
import LoginView from 'views/Login';
import ForgotPassword from 'screens/ForgotPassword';
import SelectAccount from 'screens/SelectAccount';
import useSubmit from 'hooks/useSubmit';
import AuthService from 'services/Auth';
import Storage from 'utils/Storage';
import { updateUserAccount, updateUserRole, updateUserToken } from 'store/User';
import handlePromise from 'utils/Promise';
import ProfileService from 'services/Profile';
import InvestmentAccountService from 'services/InvestmentAccount';
import { User } from 'types/User';
import { Account } from 'types/Account';
import { LoadingContext } from 'context/Loading';
import { useHistory } from 'react-router';
import { ADMIN_ACCOUNTS } from 'constants/routes';

const Login = () => {
  const dispatch = useDispatch();
  const { toggleLoading } = useContext( LoadingContext );
  const [showForgotPasswordModal, setForgotPasswordModal] = useState( false );
  const [showAccountModal, setAccountModal] = useState( false );
  const [profile, setProfile] = useState<User>();
  const [accounts, setAccounts] = useState<Account[]>( [] );
  const history = useHistory();
  const validationSchema = yup.object( {
    email: yup.string().required().email(),
    password: yup.string().required(),
  } );
  const validate = validateForm( validationSchema );

  useEffect( () => {
    Storage.clear( 'grupoimpar:token' );
  }, [] );

  const selectAccount = useCallback( ( accountId: string ) => {
    dispatch( updateUserAccount( accountId ) );
  }, [] );

  const getUser = useCallback( async () => {
    toggleLoading( true );
    const [, profileResponse, profileData] = await handlePromise( ProfileService.getProfile() );
    if ( profileResponse.ok ) setProfile( profileData );

    if ( profileData.isNew ) {
      history.push( '/force-change-password' );
      toggleLoading( false );
      return;
    }

    const [, accountsResponse, accountsData] = await handlePromise(
      InvestmentAccountService.getInvestmentAccounts(),
    );
    if ( accountsResponse.ok ) setAccounts( accountsData );

    dispatch( updateUserRole( profileData?.role ) );

    if ( !accountsData?.length && profileData?.role === 'Admin' ) {
      selectAccount( 'admin' );
      history.push( ADMIN_ACCOUNTS );
    } else if ( accountsData?.length === 1 && profileData?.role !== 'Admin' ) {
      selectAccount( accountsData[0].cuentaDeInversionId );
    } else if ( ( profileData?.role === 'Admin' && !!accountsData?.length ) || ( accountsData?.length > 1 ) ) {
      setAccountModal( true );
    }
    toggleLoading( false );
  }, [] );

  const submitForm = useSubmit( {
    promise: ( formData ) => AuthService.loginUser( {
      email: formData.email,
      password: formData.password,
    } ),
    callback: ( formData, responseData ) => {
      Storage.set( 'grupoimpar:token', responseData.token );
      dispatch( updateUserToken( responseData.token ) );
      getUser();
    },
    showAlert: false,
  } );

  return (
    <>
      <LoginView
        onSubmit={submitForm}
        validate={validate}
        openForgotPassword={() => setForgotPasswordModal( true )}
      />
      <ForgotPassword
        show={showForgotPasswordModal}
        onClose={() => setForgotPasswordModal( false )}
      />
      <SelectAccount
        show={showAccountModal}
        userRole={profile?.role}
        accounts={accounts}
        selectAccount={selectAccount}
        onClose={() => setAccountModal( false )}
      />
    </>
  );
};

export default Login;
