import React from 'react';
import * as yup from 'yup';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router';
import validateForm from 'utils/Validator';
import RecoverPasswordView from 'views/RecoverPassword';
import useSubmit from 'hooks/useSubmit';
import AuthService from 'services/Auth';
import { yupForcePassword } from 'utils/yupFields';

const RecoverPassword = ( ) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const validationSchema = yup.object( yupForcePassword( t ) );
  const validate = validateForm( validationSchema );

  const submitForm = useSubmit( {
    promise: ( formData ) => AuthService.recoverPassword( {
      token: qs.parse( location.search, { ignoreQueryPrefix: true } )?.token as string,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
    } ),
    callback: () => history.push( '/login' ),
    showAlert: false,
  } );

  return (
    <RecoverPasswordView
      onSubmit={submitForm}
      validate={validate}
    />
  );
};

export default RecoverPassword;
