/* eslint-disable max-len */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, ListGroup, Row, Col,
} from 'react-bootstrap';
import { HiOutlineDownload } from 'react-icons/hi';
import handlePromise from 'utils/Promise';
import ProjectService from 'services/Projects';
import { LoadingContext } from 'context/Loading';
import moment from 'moment';
import { getFileFromBase64 } from 'utils/downloadBase64';
import TreeMenu from 'react-simple-tree-menu';
import {
  IoChevronUpOutline, IoChevronDownOutline, IoFolderOutline, IoFolderOpen,
} from 'react-icons/io5';
import _ from 'lodash';
import classnames from 'classnames';
import CardLight from '../../CardLight';
import NoDataText from '../../NoDataText';

type DocumentsProps = {
  dataObjs: any[] | undefined;
  title?: string;
  fullHeight?: boolean;
  extraClass?: string;
  listGroupClass?: string;
};

const LEVEL_SPACE = 16;

const Documents = ( {
  dataObjs,
  fullHeight = true,
  title = 'documentation',
  extraClass,
  listGroupClass,
}: DocumentsProps ) => {
  const { t } = useTranslation();
  const { toggleLoading } = useContext( LoadingContext );
  const [dataTree, setDataTree] = useState<any>( [] );

  const downloadDocument = useCallback( async ( path, name ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getDownloadFile( { path } ),
    );
    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFileFromBase64( downloadDocumentData, name );
    }
  }, [] );

  const renderDataTree = ( item:any, level: number ) => {
    const nodes:any = [];
    _.map( item.documentos, ( itemDoc ) => {
      nodes.push( renderDataTree( itemDoc, level + 1 ) );
    } );
    return {
      key: _.uniqueId( `l-${level}` ),
      label: item.nombre,
      url: item.url,
      type: item.tipo,
      date: item.fecha,
      nodes,
    };
  };

  useEffect( () => {
    const data:any = [];
    if ( dataObjs ) {
      _.map( dataObjs, ( item ) => {
        data.push( renderDataTree( item, 1 ) );
      } );
    }
    setDataTree( data );
  }, [dataObjs] );

  return (
    <CardLight
      title={t( title )}
      fullHeight={fullHeight}
      isLoading={!dataObjs}
      extraClass={extraClass}
    >
      {( !!dataObjs && !dataObjs.length ) && <NoDataText text={t( 'dashboard.noUpcomingDeliveriesText' )} /> }
      <TreeMenu
        cacheSearch
        data={dataTree}
        debounceTime={500}
        disableKeyboard={false}
        hasSearch={false}
        resetOpenNodesOnDataUpdate={false}
      >
        {( { items } ) => (
          <>
            <ListGroup variant="flush">
              {items.map( ( {
                key, level = 0,
                hasNodes,
                isOpen,
                label,
                toggleNode,
                url,
                tipo,
                ...props
              } ) => (
                <ListGroup.Item
                  key={key}
                  className={classnames( 'pt-2 pb-2', listGroupClass )}
                  onClick={( e ) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    hasNodes && toggleNode && toggleNode();
                    e.stopPropagation();
                  }}
                  style={{
                    cursor: hasNodes ? 'pointer' : 'normal',
                  }}
                >
                  <Row>
                    <Col className="p-0">
                      {( hasNodes || ( !hasNodes && !url ) ) ? (
                        <span
                          style={{
                            paddingLeft: level * LEVEL_SPACE,
                            cursor: 'pointer',
                          }}
                          className="text--medium"
                        >
                          {( isOpen || ( !hasNodes && !url ) )
                            ? <IoFolderOpen size={16} color="#166B8E" style={{ marginRight: 5, marginTop: -3 }} />
                            : <IoFolderOutline size={16} color="#166B8E" style={{ marginRight: 5, marginTop: -3 }} />}
                          {label}
                        </span>
                      ) : (
                        <span
                          style={{
                            paddingLeft: ( level ) * LEVEL_SPACE,
                          }}
                          className="text--xs"
                        >
                          {label}
                        </span>
                      )}
                    </Col>
                    {!hasNodes && !!url && (
                    <Col xs="1" className="p-0">
                      <span
                        className="text--xs"
                      >
                        {props.type}
                      </span>
                    </Col>
                    )}
                    {!hasNodes && !!url && (
                    <Col md="2" className="">
                      <span
                        className="text--xs"
                      >
                        {props.date ? moment( props.date ).format( 'DD-MM-YYYY' ) : ''}
                      </span>
                    </Col>
                    )}
                    <Col md="auto" className="p-0 text-end">
                      {hasNodes && (
                      <div
                        style={{ display: 'inline-block' }}
                      >
                        <div role="img" aria-label="Toggle" className="rstm-toggle-icon-symbol">
                          {isOpen ? <IoChevronUpOutline size={18} color="#166B8E" />
                            : <IoChevronDownOutline size={18} color="#166B8E" />}
                        </div>
                      </div>
                      )}
                      {!hasNodes && !!url && (
                      <Button
                        variant="link"
                        size="sm"
                        className="text--medium p-0"
                        onClick={async () => {
                          await downloadDocument( url, label );
                        }}
                      >
                        <HiOutlineDownload color="#166B8E" size={22} />
                      </Button>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              ) )}
            </ListGroup>
          </>
        )}
      </TreeMenu>
    </CardLight>
  );
};

export default Documents;
