export const b64toBlob = ( b64Data: string, contentType = '', sliceSize = 512 ) => {
  const byteCharacters = atob( b64Data );
  const byteArrays = [];

  for ( let offset = 0; offset < byteCharacters.length; offset += sliceSize ) {
    const slice = byteCharacters.slice( offset, offset + sliceSize );

    const byteNumbers = new Array( slice.length );

    // eslint-disable-next-line no-plusplus
    for ( let i = 0; i < slice.length; i++ ) {
      byteNumbers[i] = slice.charCodeAt( i );
    }

    const byteArray = new Uint8Array( byteNumbers );
    byteArrays.push( byteArray );
  }

  return new Blob( byteArrays, { type: contentType } );
};

export const getFileFromBase64 = ( downloadDocumentData: string, name:string ) => {
  const posBase = downloadDocumentData.indexOf( 'base64,' );
  const type = downloadDocumentData.substring( 5, posBase );

  const dataResult = downloadDocumentData.replace( 'data:application/pdf;base64,', '' );
  const blob = b64toBlob( dataResult, type );
  const blobUrl = URL.createObjectURL( blob );
  const extension = type.replace( ';', '' ).split( '/' );

  const link = document.createElement( 'a' );
  link.href = blobUrl;
  link.download = `${name}.${extension.length ? extension[1] : 'pdf'}`;
  link.click();
};

export const getFile = ( downloadDocumentData: string, name:string,
  type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ext = 'xlsx' ) => {
  try {
    const blob = new Blob( [downloadDocumentData], { type } );
    const link = document.createElement( 'a' );
    link.href = window.URL.createObjectURL( blob );
    link.download = `${name}-${( new Date() ).valueOf()}.${ext}`;
    link.click();
  } catch ( e ) {
    console.log( 'error', e );
  }
};
