import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  DASHBOARD, FUNDS, HISTORIC, PROJECTS,
} from 'constants/routes';
import {
  Container, Dropdown, Nav, NavDropdown,
} from 'react-bootstrap';
import { convertToSlug } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const TabsHeader = ( { projects, funds }:{ funds:any[], projects:any[] } ) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div className="nav__subheader d-none d-md-block">
      <Container>
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link as={NavLink} to={DASHBOARD} exact>
              Posición global
            </Nav.Link>
          </Nav.Item>

          {( !!projects.length || !!funds.length )
          && (
          <NavDropdown
            title={t( 'activeInvestments' )}
            className="nav__subheader-projects"
            id="projects-nav-dropdown"
            active={location.pathname.startsWith( PROJECTS )
                || location.pathname.startsWith( FUNDS )}
          >
            {funds.length > 0 && (
            <Dropdown as={Nav.Item} drop="end">
              <Dropdown.Toggle as={Nav.Link}>
                {t( 'fundsSubMenu' )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {funds.map( ( item ) => (
                  <NavDropdown.Item
                    key={item.promocionId}
                    as={NavLink}
                    to={`${FUNDS}/${item.promocionId}/${convertToSlug( item.codigoPromocion )}`}
                    activeClassName=""
                    className="text-decoration-none"
                  >
                    {item.codigoPromocion}
                  </NavDropdown.Item>
                ) )}
              </Dropdown.Menu>
            </Dropdown>
            )}

            {!!projects && projects.length > 0 && (
            <Dropdown as={Nav.Item} drop="end">
              <Dropdown.Toggle as={Nav.Link}>
                {t( 'projectsSubMenu' )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {projects.map( ( item ) => (
                  <NavDropdown.Item
                    key={item.promocionId}
                    as={NavLink}
                    to={`${PROJECTS}/${item.promocionId}/${convertToSlug( item.codigoPromocion )}`}
                    activeClassName=""
                    className="text-decoration-none"
                  >
                    {item.codigoPromocion}
                  </NavDropdown.Item>
                ) )}
              </Dropdown.Menu>
            </Dropdown>
            )}

          </NavDropdown>
          )}

          <Nav.Item>
            <Nav.Link as={NavLink} to={HISTORIC}>
              Histórico
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link as={NavLink} to={NEWS}>Noticias</Nav.Link>
          </Nav.Item> */}
        </Nav>
      </Container>
    </div>
  );
};

export default TabsHeader;
