import React, { useCallback, useEffect, useState } from 'react';
import Header from 'components/User/Header';
import TabsHeader from 'components/User/TabsHeader';
import { Container, Spinner } from 'react-bootstrap';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import {
  DASHBOARD, HISTORIC, PROJECTS, NEWS, PROFILE, NEWS_DETAIL, ACTIVE_PROJECTS, FUNDS,
} from 'constants/routes';
import Dashboard from 'screens/User/Dashboard';
import Project from 'screens/User/Project';
import Funds from 'screens/User/Funds';
import Historic from 'screens/User/Historic';
import News from 'screens/User/News';
import Profile from 'screens/User/Profile';
import handlePromise from 'utils/Promise';
import InvestmentAccountService from 'services/InvestmentAccount';
import ProjectsService from 'services/Projects';
import _ from 'lodash';
import ProfileService from 'services/Profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserAccount, updateUserAccount } from 'store/User';
import { useHistory } from 'react-router';
import ActiveProjects from 'screens/User/ActiveProjects';
import NewsDetail from '../News/Detail';

const UserLayout = () => {
  const history = useHistory();
  const [accounts, setAccounts] = useState<any[]>( [] );
  const [projects, setProjects] = useState<any[]>( [] );
  const [funds, setFunds] = useState<any[]>( [] );
  const [profile, setProfile] = useState<any>();
  const [readyLayout, setReadyLayout] = useState<boolean>( false );
  const userAccountId = useSelector( selectUserAccount );
  const dispatch = useDispatch();

  const getProfileData = useCallback( async () => {
    const [, profileResponse, profileData] = await handlePromise( ProfileService.getProfile() );
    if ( profileData?.isNew ) {
      history.push( '/force-change-password' );
      return;
    }
    if ( profileResponse.ok ) setProfile( profileData );
  }, [] );

  const getAccount = useCallback( async () => {
    // GET ACCOUNTS
    const [, accountsResponse, accountsData] = await handlePromise(
      InvestmentAccountService.getInvestmentAccounts(),
    );
    if ( accountsResponse.ok ) {
      const accountsList = _.sortBy( accountsData, 'nameCuentadeInversion' );
      setAccounts( accountsList );

      if ( ( !userAccountId || userAccountId === 'admin' ) && accountsList.length ) {
        dispatch( updateUserAccount( accountsList[0].cuentaDeInversionId ) );
      }

      // GET PROJECTS
      const [, projectsResponse, projectsData] = await handlePromise(
        ProjectsService.getPromociones( userAccountId, { tipoDeProyecto: 'Promoción' } ),
      );
      if ( projectsResponse.ok ) setProjects( projectsData?.promocionesActivos );

      // GET FUNDS
      const [, fundsResponse, fundsData] = await handlePromise(
        ProjectsService.getPromociones( userAccountId, { tipoDeProyecto: 'Fondo' } ),
      );
      if ( fundsResponse.ok ) setFunds( fundsData?.promocionesActivos );

      if ( ( history.location.pathname.indexOf( PROJECTS ) !== -1
          && projectsData.promocionesActivos.length )
          || ( history.location.pathname.indexOf( FUNDS ) !== -1
          && fundsData.promocionesActivos.length ) ) {
        setReadyLayout( true );
      } else {
        setTimeout( () => {
          setReadyLayout( true );
        }, 800 );
      }
    }
  }, [userAccountId] );

  useEffect( () => {
    getProfileData();
    getAccount();
  }, [userAccountId] );

  return (
    <>
      <Header accounts={accounts} profile={profile} projects={projects} />
      <TabsHeader projects={projects} funds={funds} />

      <Container fluid="xl" className="body-container p-0">
        {readyLayout ? (
          <Switch>
            <Route path={HISTORIC} component={Historic} />
            <Route path={NEWS} component={News} />
            <Route path={NEWS_DETAIL} component={NewsDetail} />
            <Route path={`${PROJECTS}/:id/:slug`} component={Project} />
            <Route path={`${FUNDS}/:id/:slug`} component={Funds} />
            <Route path={PROFILE} component={Profile} />
            <Route path={ACTIVE_PROJECTS} component={ActiveProjects} />
            <Route path={DASHBOARD} exact component={Dashboard} />
            <Redirect from="*" to="/" />
          </Switch>
        )
          : (
            <div className="app-loading">
              <Spinner
                animation="border"
                variant="primary"
                style={{
                  width: '3rem',
                  height: '3rem',
                }}
              />
            </div>
          )}

      </Container>
    </>
  );
};

export default UserLayout;
