import React from 'react';
import { FormProps } from 'react-final-form';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';
import logo from 'assets/img/logo-blue-full.png';
import Form from 'components/Form/Layout';
import TextField from 'components/Form/FormTextField';
import PasswordInput from 'components/PasswordInput';

type LoginProps = {
  validate: ( values: any ) => Promise<ValidationError>,
  openForgotPassword: () => void,
  onSubmit: ( values: any ) => void
};

const Login = ( { onSubmit, validate, openForgotPassword }: LoginProps ) => {
  const { t } = useTranslation();

  return (
    <Container fluid className="px-4 body-login-container">
      <Row className="vh-100">
        <Col xs={12} md={9} lg={6} className="m-auto card--light py-5 px-md-0 px-lg-5">
          <Form
            validate={validate}
            onSubmit={onSubmit}
            body={(
              <>
                <div className="text-center mb-5 d-none d-md-block">
                  <img src={logo} height={55} alt="..." />
                </div>
                <div className="text-center mb-5 d-md-none">
                  <img src={logo} height={40} alt="..." className="" />
                </div>
                <Row>
                  <Col md={10} className="m-auto">
                    <TextField
                      field="email"
                      label="email"
                    />
                    <PasswordInput
                      field="password"
                      label="password"
                      classNames={{ formGroup: 'mb-2', input: 'password' }}
                    />

                    <Button
                      variant="link"
                      size="sm"
                      className="pt-0 text--normal"
                      onClick={openForgotPassword}
                    >
                      {t( 'forgotPassword.question' )}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            footer={( form: FormProps ) => (
              <Col md={10} className="m-auto mt-4">
                <Button variant="primary" type="submit" className="btn--full-width" disabled={form.getState().hasValidationErrors}>
                  {t( 'login' )}
                </Button>
              </Col>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
