import React, { useCallback, useContext } from 'react';
import ActiveProjectsView from 'views/User/ActiveProjects';
import ProjectService from 'services/Projects';
import { useSelector } from 'react-redux';
import useFetchTableData from 'hooks/useFetchTableData';
import { selectUserAccount } from 'store/User';
import handlePromise from 'utils/Promise';
import { getFile } from 'utils/downloadBase64';
import { LoadingContext } from 'context/Loading';

const ActiveProjects = () => {
  const { toggleLoading } = useContext( LoadingContext );
  const userAccountId = useSelector( selectUserAccount );

  const downloadFile = useCallback( async ( ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getPdfResumenProyectos( userAccountId, { responseType: 'blob' } ),
    );

    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFile( downloadDocumentData, 'Pdf-Resumen-Proyectos', 'application/pdf', 'pdf' );
    }
  }, [userAccountId] );

  const { fetchData, data, dataLoading } = useFetchTableData( {
    promise: ( params ) => ProjectService.getPromocionesActivos( userAccountId, params ),
    deps: [userAccountId],
  } );

  return (
    <ActiveProjectsView
      projects={data}
      fetchData={fetchData}
      isLoading={dataLoading}
      downloadFile={downloadFile}
    />
  );
};
export default ActiveProjects;
