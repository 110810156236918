import React from 'react';
import { UsePaginationInstanceProps, UsePaginationState } from 'react-table';
import {
  Pagination, Row, Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from '../Form/FormSelect';

const RPagination = ( {
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  setPageSize,
  pageSize,
  pageCount,
  pageIndex,
  gotoPage,
  fullText = true,
  options = ['10', '20', '30'],
}: Partial<UsePaginationInstanceProps<any>> &
Partial<UsePaginationState<any>> &
{ fullText?: boolean, options?: string[] } ) => {
  const { t } = useTranslation();
  const currentPage = Number( pageIndex );
  return (
    <Row className="mt-4">
      <Col className="mb-3 mb-sm-0">
        <Row className="gx-3 align-items-center">
          <Col xs="auto">
            <span className="text--xs">{t( 'show' )}</span>
          </Col>
          <Col xs="auto">
            <Select
              value={pageSize}
              options={options?.map( ( item ) => (
                { id: item, name: `${item} ${fullText ? t( 'records' ) : ''}` }
              ) )}
              onChange={( value ) => ( setPageSize ? setPageSize( Number( value ) ) : {} )}
              classNames={{ formGroup: 'table__page-size' }}
            />
          </Col>
        </Row>
      </Col>

      {!!pageCount && (
      <Col xs="auto">
        <Pagination>

          <Pagination.Item
            className="page-item__prev"
            disabled={!( canPreviousPage && previousPage )}
            onClick={() => {
              if ( canPreviousPage && previousPage ) previousPage();
            }}
          >
            <i className="icon-arrow-left" />
          </Pagination.Item>

          {!!pageCount && pageCount === currentPage + 1
          && pageCount > 2 && (
            <Pagination.Item onClick={() => {
              if ( gotoPage )gotoPage( currentPage === 0 ? 1 : currentPage - 1 );
            }}
            >
              {currentPage === 0 ? 1 : currentPage - 1}
            </Pagination.Item>
          )}

          <Pagination.Item
            active={currentPage === 0}
            onClick={() => {
              if ( gotoPage && currentPage !== 0 )gotoPage( currentPage - 1 );
            }}
          >
            {currentPage === 0 ? 1 : currentPage}
          </Pagination.Item>

          {!!pageCount && pageCount > 1 && (
          <Pagination.Item
            active={currentPage !== 0}
            onClick={() => {
              if ( gotoPage && currentPage === 0 )gotoPage( currentPage + 1 );
            }}
          >
            {currentPage !== 1 && currentPage > 0 ? currentPage + 1 : 2}
          </Pagination.Item>
          )}

          {!!pageCount && pageCount > currentPage + 1 && pageCount > 2 && (
          <Pagination.Item
            active={currentPage === pageCount - 1}
            onClick={() => {
              if ( gotoPage && currentPage !== pageCount - 1 ) {
                gotoPage( currentPage === 0 ? 2 : currentPage + 1 );
              }
            }}
          >
            {currentPage > 1 ? currentPage + 2 : 3}
          </Pagination.Item>
          )}

          {!!pageCount && ( pageCount - 3 ) > currentPage
              && ( <Pagination.Ellipsis /> )}
          {!!pageCount && ( pageCount - 2 ) > currentPage
              && (
              <Pagination.Item
                href="#"
                onClick={() => {
                  if ( gotoPage )gotoPage( pageCount - 1 );
                }}
              >
                {pageCount}
              </Pagination.Item>
              )}

          <Pagination.Item
            className="page-item__next"
            disabled={!( canNextPage && nextPage )}
            onClick={() => {
              if ( canNextPage && nextPage ) nextPage();
            }}
          >
            <i className="icon-arrow-right" />
          </Pagination.Item>
        </Pagination>
      </Col>
      )}

    </Row>
  );
};

export default RPagination;
