import React from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';
import PasswordInput from 'components/PasswordInput';
import Form from 'components/Form/Layout';

type RecoverPasswordProps = {
  validate: ( values: any ) => Promise<ValidationError>,
  onSubmit: ( values: any ) => void,
  force?: boolean,
};

const RecoverPassword = ( { validate, onSubmit, force = false }: RecoverPasswordProps ) => {
  const { t } = useTranslation();

  return (
    <Container fluid className="px-4 body-login-container">
      <Row className="vh-100">
        <Col xs={12} md={10} lg={5} className="m-auto card--light py-5 px-md-5">
          <Form
            validate={validate}
            onSubmit={onSubmit}
            body={(
              <>
                <h3 className="text-center mb-4">{t( 'recoverPassword.title' )}</h3>

                <Row>
                  <Col md={10} className="m-auto">
                    {force && (
                    <PasswordInput
                      field="currentPassword"
                      label="profile.currentPassword"
                      classNames={{ formGroup: 'mb-2' }}
                    />
                    )}
                    <div className="info-card--light mb-5">
                      <p className="text--xs text--bold m-0">{t( 'recoverPassword.requirementsTitle' )}</p>
                      <ul className="m-0">
                        <li className="text--xs">{t( 'recoverPassword.requirements1' )}</li>
                        <li className="text--xs">{t( 'recoverPassword.requirements2' )}</li>
                        <li className="text--xs">{t( 'recoverPassword.requirements3' )}</li>
                        <li className="text--xs">{t( 'recoverPassword.requirements4' )}</li>
                        <li className="text--xs">{t( 'recoverPassword.requirements5' )}</li>
                      </ul>
                    </div>

                    <PasswordInput field="password" label="newPassword" />
                    <PasswordInput field="confirmPassword" label="repeatPassword" />
                  </Col>
                </Row>
              </>
            )}
            footer={( ) => (
              <Col md={10} className="m-auto mt-2">
                <Button variant="primary" type="submit" className="btn--full-width">
                  {t( 'confirm' )}
                </Button>
              </Col>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default RecoverPassword;
