import React from 'react';
import DarkTile from 'components/DarkTile';
import icon1 from 'assets/img/dashboard-icon1.svg';
import icon2 from 'assets/img/dashboard-icon2.svg';
import icon3 from 'assets/img/dashboard-icon3.svg';
import icon4 from 'assets/img/dashboard-icon4.svg';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BodyContent from 'components/BodyContent';
import { KpiAccount } from 'types/Account';
import numeral from 'numeral';
import { formatCurrencyNoDecimal, formatPercent } from 'utils/formats';
import CharDoughnut from 'components/Char/CharDoughnut';
import MapChar from 'components/Char/map';
import NextDeliveriesCard from 'components/Dashboard/NextDeliveries';
import LastReports from 'components/Dashboard/LastReports';
import CashFlow from 'components/Char/CashFlow';
import MillionNumeral from 'components/MillionNumeral';
import { DTable } from 'types/table';
import CardLight from 'components/CardLight';
import ActiveProjects from 'components/Dashboard/ActiveProjects';
import { useHistory } from 'react-router';
import { ACTIVE_PROJECTS } from 'constants/routes';

type DashboardProps = {
  onOpenMapModal: ( { params }: { params: any } ) => void
  kpiAccount: KpiAccount | null;
  projects: any[] | undefined;
  nextDeliveries: any[] | undefined;
  mapInfo: any[] | undefined;
  myCashFlow: any[] | undefined;
  latestReports: any[] | undefined;
  dataLoaded: boolean;
  isLoading?:boolean;
  activeProjects:DTable;
  fetchData: ( params:any, pageSize:number )=>void ;
  downloadFile: ( type:'projects' | 'flow' )=>void ;
};

const Dashboard = ( {
  onOpenMapModal,
  kpiAccount,
  projects,
  mapInfo,
  nextDeliveries,
  myCashFlow,
  latestReports,
  dataLoaded,
  activeProjects,
  fetchData,
  isLoading,
  downloadFile,
}: DashboardProps ) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <BodyContent
        classNames={{ body: 'position-relative' }}
        title={(
          <div className="px-3 pt-3 pb-2">
            <h1>
              {t( 'globalPosition' )}
            </h1>
          </div>
        )}
      >
        {dataLoaded && !!projects && !projects.length
        && (
          <div>
            <div className="app-no-info" />
            <div className="app-no-info--text">
              <img src={icon4} alt="" width={64} />
              <h1 className="text--bold mt-3">
                No tienes inversiones
                <br />
                {' '}
                en este momento
              </h1>
            </div>
          </div>
        )}
        <div className="row align-items-stretch g-3 g-md-4 mb-4">
          <DarkTile
            icon={icon3}
            title={( <MillionNumeral number={kpiAccount?.capitalActualInvertido} /> )}
            subtitle="Capital suscrito"
          />
          <DarkTile
            icon={icon2}
            title={( <MillionNumeral number={kpiAccount?.beneficiosEstimados} /> )}
            subtitle="Beneficios estimados"
            subtitle2={t( 'beforeFee' )}
          />
          <DarkTile
            icon={icon1}
            title={numeral( kpiAccount?.roiEstimado
              ? kpiAccount?.roiEstimado / 100 : 0 ).format( formatPercent )}
            subtitle="ROE medio estimado"
            subtitle2={t( 'beforeFee' )}
          />
          <DarkTile
            icon={icon4}
            title={numeral( kpiAccount?.tirEstimada
              ? kpiAccount.tirEstimada / 100 : 0 ).format( formatPercent )}
            subtitle="TIR media estimada"
            subtitle2={t( 'beforeFee' )}
          />
        </div>

        <Row>
          <Col md={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
            <CharDoughnut
              itemKey="myChart1"
              dataObjs={projects}
              title={t( 'dashboard.invertedPromotions' )}
              label="codigoPromocion"
              val="capital"
              id="promocionId"
              formatVal={formatCurrencyNoDecimal}
              headers={[' ', t( 'promotion' ), false, t( 'capital' )]}
              indicators={!!projects && projects.length > 4}
            />
          </Col>

          <Col className="mt-4 mt-md-0" md={{ span: 6, order: 2 }} xl={{ span: 4, order: 2 }}>
            <MapChar
              dataObjs={mapInfo}
              onOpenMapModal={onOpenMapModal}
            />
          </Col>

          <Col className="mt-4 mt-xl-0" md={{ span: 6, order: 4 }} xl={{ span: 4, order: 3 }}>
            <LastReports dataObjs={latestReports} />
          </Col>

          <Col md={{ span: 12, order: 3 }} className="d-none d-sm-block">
            <ActiveProjects
              activeProjects={activeProjects}
              fetchData={fetchData}
              isLoading={isLoading}
              downloadFile={downloadFile}
            />
          </Col>
          <Col md={{ span: 12, order: 2 }} className="mt-4 d-md-none">
            <CardLight title={t( 'dashboard.activeProjects' )}>
              <div>{t( 'projects.totalProjects', { total: activeProjects.totalItems } )}</div>
              <div className="mt-3 text-end">
                <Button
                  variant="link"
                  size="sm"
                  className="text--medium p-0 text--lato"
                  onClick={async () => {
                    history.push( ACTIVE_PROJECTS );
                  }}
                >
                  {t( 'seeDetail' )}
                </Button>
              </div>
            </CardLight>
          </Col>

          <Col md={{ span: 12, order: 3 }} xl={{ span: 8, order: 4 }} className="mt-4">
            <CashFlow dataObjs={myCashFlow} downloadFile={downloadFile} />
          </Col>

          <Col className="mt-4" md={{ span: 6, order: 5 }} xl={{ span: 4, order: 5 }}>
            <NextDeliveriesCard dataObjs={nextDeliveries} />
          </Col>
        </Row>
      </BodyContent>
    </>
  );
};

export default Dashboard;
