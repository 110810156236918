import React, { ReactNode } from 'react';
import classnames from 'classnames';

type BodyContentProps = {
  title?: ReactNode;
  children: ReactNode;
  showTitleInTablet?: boolean;
  classNames?: {
    body?:string
  };
};

const BodyContent = ( {
  title, children, showTitleInTablet, classNames,
}: BodyContentProps ) => (
  <>
    {title && (
      <div className="d-md-none">
        {title}
      </div>
    )}
    <div className={classnames( 'body-content', classNames?.body )}>
      {showTitleInTablet && (
        <div className="d-none d-md-block">
          {title}
        </div>
      )}

      {children}
    </div>
  </>
);

export default BodyContent;
