import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { persistor, store } from './store';
import * as serviceWorker from './serviceWorker';
import LoadingProvider from './context/Loading';
import ModalErrorProvider from './context/ModalError';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoadingProvider>
          <ModalErrorProvider>
            <BrowserRouter>
              <Switch>
                <Route path="/" component={App} />
                <Redirect from="*" to="/" />
              </Switch>
            </BrowserRouter>
          </ModalErrorProvider>
        </LoadingProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById( 'root' ),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
