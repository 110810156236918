import React from 'react';
import {
  Modal, Button, Row, Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Account } from 'types/Account';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import { ADMIN_ACCOUNTS } from 'constants/routes';
import _ from 'lodash';

type SelectAccountProps = {
  show: boolean,
  onClose: () => void,
  accounts: Account[],
  role?: string,
  onSelectAccount: ( accountId: string ) => void,
};

const SelectAccount = ( {
  show, onClose, accounts, role, onSelectAccount,
}: SelectAccountProps ) => {
  const { t } = useTranslation();
  const history = useHistory();
  const myAccounts: any = _.filter( accounts, { miInversion: true } );
  return (
    <Modal
      show={show}
      centered
      onHide={onClose}
    >
      <Modal.Header />
      <Modal.Body>
        <h3 className="text-center mb-md-4 mb-3">{t( 'selectAccount.title' )}</h3>
        <p className="text-center">{t( 'selectAccount.description' )}</p>

        <Row>
          <Col md={7} className="m-auto mt-3">
            {myAccounts.map( ( account:any, index: number ) => (
              <Button
                key={account.cuentaDeInversionId}
                variant="outline-primary"
                size="sm"
                className={classnames( 'btn--full-width justify-content-between d-flex', {
                  'mt-4': index > 0,
                } )}
                onClick={() => onSelectAccount( account.cuentaDeInversionId )}
              >
                {account.nameCuentadeInversion}
                <i className="icon-arrow-right" />
              </Button>
            ) )}

            {role === 'Admin'
            && (
            <Button
              variant="outline-warning"
              size="sm"
              className="btn--full-width justify-content-between d-flex mt-4"
              onClick={() => {
                onSelectAccount( 'admin' );
                history.push( ADMIN_ACCOUNTS );
              }}
            >
              Panel de administración
              <i className="icon-arrow-right" />
            </Button>
            )}
          </Col>
        </Row>

      </Modal.Body>
    </Modal>
  );
};

export default SelectAccount;
