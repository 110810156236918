import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import ProjectView from 'views/User/Project';
import VideoModal from 'components/Modal/VideoModal';
import { useSelector } from 'react-redux';
import { selectUserAccount } from 'store/User';
import handlePromise from 'utils/Promise';
import ProjectService from 'services/Projects';
import { KpiResumeProject } from 'types/Project';
import { useParams } from 'react-router-dom';
import { getFile, getFileFromBase64 } from 'utils/downloadBase64';
import { LoadingContext } from 'context/Loading';

const Project = () => {
  const { id }:{ id:any; type:any } = useParams();
  const userAccountId = useSelector( selectUserAccount );
  const { toggleLoading } = useContext( LoadingContext );
  const [showVideoModal, setVideoModal] = useState<{
    status: boolean;
    key:number ;
    index:number
  }>( {
    status: false, key: -1, index: -1,
  } );
  const [kpiProject, setKpiProject] = useState<KpiResumeProject | null>( null );
  const [marketingSummary, setMarketingSummary] = useState<[] | undefined>();
  const [financingTypes, setFinancingTypes] = useState<[] | undefined>( );
  const [documents, setDocuments] = useState<[] | undefined>( );
  const [periods, setPeriods] = useState( [] );
  const [photographicAdvance, setPhotographicAdvance] = useState<[] | undefined>( );
  const [workProgress, setWorkProgress] = useState<{ conceptosAvanceObra: [] | undefined;
    avanceGeneral: number }>(
    { conceptosAvanceObra: undefined, avanceGeneral: 0 },
  );

  const openVideoModal = useCallback( ( index, key ) => {
    setVideoModal( {
      status: true,
      key,
      index,
    } );
  }, [photographicAdvance] );

  const closeVideoModal = useCallback( () => {
    setVideoModal( {
      status: false,
      key: -1,
      index: -1,
    } );
  }, [] );

  const getAvanceFotografico = useCallback( async ( key ) => {
    const [, photographicAdvanceResponse, photographicAdvanceResponseData] = await handlePromise(
      ProjectService.getAvanceFotografico( userAccountId, id, key ),
    );
    if ( photographicAdvanceResponse.ok ) {
      setPhotographicAdvance( photographicAdvanceResponseData );
    }
  }, [userAccountId, id] );

  const downloadFile = useCallback( async ( ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getUltimoInformeFinancieroPromocion( userAccountId, id ),
    );
    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFileFromBase64( downloadDocumentData, `${kpiProject?.codigoPromocion}_ultimo-informe-financiero` );
    }
  }, [userAccountId, id, kpiProject] );

  const downloadFilePdf = useCallback( async ( ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getInformePdfByPromocion( userAccountId, id, { responseType: 'blob' } ),
    );

    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFile( downloadDocumentData, `${kpiProject?.codigoPromocion}-informe-por-promoción`, 'application/pdf', 'pdf' );
    }
  }, [userAccountId, id, kpiProject] );

  const getData = useCallback( async () => {
    const [, kpiProjectResponse, kpiProjectData] = await handlePromise(
      ProjectService.getKPIPromocionById( userAccountId, id ),
    );
    if ( kpiProjectResponse.ok ) setKpiProject( kpiProjectData );

    setWorkProgress( { conceptosAvanceObra: undefined, avanceGeneral: 0 } );
    const [, workProgressResponse, workProgressData] = await handlePromise(
      ProjectService.getAvanceObra( userAccountId, id ),
    );
    if ( workProgressResponse.ok ) setWorkProgress( workProgressData );

    setMarketingSummary( undefined );
    const [, marketingSummaryResponse, marketingSummaryData] = await handlePromise(
      ProjectService.getResumenComercializacion( userAccountId, id ),
    );
    if ( marketingSummaryResponse.ok ) setMarketingSummary( marketingSummaryData );

    setFinancingTypes( undefined );
    const [, financingTypesResponse, financingTypesData] = await handlePromise(
      ProjectService.getTiposFinanciacion( userAccountId, id ),
    );
    if ( financingTypesResponse.ok ) setFinancingTypes( financingTypesData );

    setDocuments( undefined );
    const [, documentProjectResponse, documentProjectData] = await handlePromise(
      ProjectService.getDocumentacion( userAccountId, id ),
    );
    if ( documentProjectResponse.ok ) setDocuments( documentProjectData );

    setPhotographicAdvance( undefined );
    setPeriods( [] );
    const [, periodsResponse, periodsData] = await handlePromise(
      ProjectService.getAvanceFotograficoPeriodos( userAccountId, id ),
    );
    if ( periodsResponse.ok && periodsData.length ) {
      setPeriods( periodsData );
      const { key } = periodsData[0];

      await getAvanceFotografico( key );
    }
  }, [userAccountId, id] );

  useEffect( () => {
    getData();
  }, [userAccountId, id] );

  return (
    <>
      <ProjectView
        onOpenVideoModal={openVideoModal}
        kpiProject={kpiProject}
        workProgress={workProgress}
        marketingSummary={marketingSummary}
        financingTypes={financingTypes}
        documents={documents}
        periods={periods}
        photographicAdvance={photographicAdvance}
        getAvanceFotografico={getAvanceFotografico}
        downloadFile={downloadFile}
        downloadFilePdf={downloadFilePdf}
      />
      <VideoModal
        show={showVideoModal.status}
        selectedKey={showVideoModal.key}
        selectedIndex={showVideoModal.index}
        onClose={closeVideoModal}
        photographicAdvance={photographicAdvance}
      />
    </>
  );
};

export default Project;
