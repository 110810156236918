import React, { ReactNode } from 'react';
import { Row, Col } from 'react-bootstrap';

type CashFlowProps = {
  title: string | ReactNode;
  className: string;
};
const CashFlowItemLegend = ( {
  className, title,
}: CashFlowProps ) => (
  <Row className="align-items-center">
    <Col xs="auto">
      <div className={className} />
    </Col>
    <Col className="ps-0"><span>{title}</span></Col>
  </Row>
);

export default CashFlowItemLegend;
