import React, { useEffect, useState } from 'react';
import {
  Button, Col, Modal, Row,
} from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'react-i18next';
import mapStyle from 'styles/map/mapstyle.json';
import { convertToSlug } from 'utils/utils';

type MapModalProps = {
  mapDetail: {
    province: string,
    list: any[],
    defaultCoors: {
      lat: number,
      lng: number,
    },
  },
  show: boolean,
  onClose: () => void,
};

const MapMarker = ( {
  inversion,
}: any ) => (
  <div>
    {inversion ? ( <div className="chart-legend cursor-pointer" style={{ backgroundColor: '#166B8E' }} /> )
      : ( <div className="chart-legend cursor-pointer" style={{ backgroundColor: '#3C3C3B' }} /> )}
  </div>
);
const InfoMarker = ( {
  text, itemId, showInfo,
}: any ) => (
  <div>
    {showInfo
      ? (
        <div>
          <div className="marker-info">
            <div className="marker-info-header">
              {text}
            </div>
            <Button
              className="p-0 m-0 btn--full-width"
              variant="link"
              onClick={() => {
                window.open( `/projects/${itemId}/${convertToSlug( text )}`, '_blank' );
              }}
            >
              <span className="text--sm text--normal">Abrir proyecto</span>
            </Button>
          </div>
        </div>
      ) : null}
  </div>
);
const MapModal = ( { show, onClose, mapDetail }: MapModalProps ) => {
  const { t } = useTranslation();
  const [showMarker, setShowMarker] = useState<number | null>( null );

  useEffect( () => {
    if ( !show ) setShowMarker( null );
  }, [show] );

  const mapOptions = {
    styles: mapStyle,
    clickableIcons: false,
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = ( map: { fitBounds: ( arg0: any ) => void; },
    maps: any,
    places: { latitud: string; longitud: string; }[] ) => {
    const bounds = new maps.LatLngBounds();

    const finalPlaces = places.filter( ( item ) => {
      if ( item.latitud && item.longitud ) {
        return true;
      }
      return false;
    } );
    if ( finalPlaces.length > 1 ) {
      finalPlaces.forEach( ( location:{ latitud:string;longitud:string } ) => {
        if ( location.latitud && location.longitud ) {
          bounds.extend(
            new maps.LatLng( location.latitud, location.longitud ),
          );
        }
      } );
      map.fitBounds( bounds );
      maps.event.addDomListenerOnce( map, 'idle', () => {
        maps.event.addDomListener( window, 'resize', () => {
          map.fitBounds( bounds );
        } );
      } );
    }
  };

  // @ts-ignore
  return (
    <Modal
      show={show}
      size="lg"
      className="modal-carousel"
      centered
      onHide={onClose}
    >
      <Modal.Header closeButton>

        <div className="text-center w-100">
          <h3 className="text-center">
            {`Promociones en ${mapDetail.province}`}
          </h3>
          <Row className="mt-2 mx-auto justify-content-center">
            <Col xs="auto"><div className="chart-legend" style={{ backgroundColor: '#166B8E' }} /></Col>
            <Col xs="auto" className="p-0">{t( 'Mis inversiones' )}</Col>
            <Col xs="auto"><div className="chart-legend" style={{ backgroundColor: '#3C3C3B' }} /></Col>
            <Col xs="auto" className="p-0">{t( 'Otras inversiones' )}</Col>
          </Row>
        </div>

      </Modal.Header>
      <Modal.Body className="p-0">
        <div style={{ height: '50vh', width: '100%' }}>
          {!!mapDetail?.defaultCoors && (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyAN1giCLCIAvU_izVyxWS5nBoHxPaJJl7g',
            }}
            defaultCenter={mapDetail.defaultCoors}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={( { map, maps } ) => {
              if ( mapDetail.list.length ) apiIsLoaded( map, maps, mapDetail.list );
            }}
            onChildMouseEnter={( hoverKey, childProps ) => {
              if ( childProps.itemId ) setShowMarker( childProps.itemId );
            }}
            onChildMouseLeave={() => {
              setShowMarker( null );
            }}
            options={mapOptions}
          >
            {!!mapDetail.list.length && mapDetail.list.map( ( item, index ) => (
              <MapMarker
                key={item.promocionId}
                index={index}
                lat={item.latitud}
                lng={item.longitud}
                inversion={item.inversion}
                itemId={item.promocionId}
              />
            ) )}
            {!!mapDetail.list.length && mapDetail.list.map( ( item, index ) => (
              <InfoMarker
                key={item.promocionId}
                index={index}
                lat={item.latitud}
                lng={item.longitud}
                text={item.codigoPromocion}
                itemId={item.promocionId}
                showInfo={!!showMarker && showMarker === item.promocionId}
              />
            ) )}
          </GoogleMapReact>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MapModal;
