import api from './index';

export default {

  loginUser( data: { email: string, password: string } ) {
    return api.post( 'api/authentication/login', data );
  },

  forgotPassword( data: { email: string } ) {
    return api.post( 'api/authentication/forgotpassword', data );
  },

  recoverPassword( data: { token: string, password: string, confirmPassword: string } ) {
    return api.post( 'api/authentication/resetpassword', data );
  },

  changePasswordByAdmin( data: { userId: number, password: string, confirmPassword: string } ) {
    return api.post( 'api/authentication/changePasswordBO', data );
  },

  getTokenImpersonate( params:any ) {
    return api.post( 'api/authentication/impersonate', params );
  },
};
