import React, { useCallback } from 'react';
import logo from 'assets/img/logo-white-full.png';
import {
  Container, Navbar, Nav, NavDropdown, Accordion,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ADMIN_ACCOUNTS,
  DASHBOARD, HISTORIC, PROFILE, PROJECTS,
} from 'constants/routes';
import img from 'assets/img/user.png';
import { useDispatch, useSelector } from 'react-redux';
import Storage from 'utils/Storage';
import {
  logoutUser, selectUserAccount, selectUserRole, updateUserAccount,
} from 'store/User';
import _ from 'lodash';
import { Profile } from 'types/User';
import { useHistory } from 'react-router';
import { convertToSlug } from 'utils/utils';
import userImg from '../../../assets/img/icons/user_profile.svg';

type NavItemMobileType = { label: string, link?: string, onClick?: () => void };
const NavItemMobile = ( { label, link, onClick }: NavItemMobileType ) => (
  <Nav.Item>
    <Navbar.Toggle>
      {link ? (
        <Nav.Link as={NavLink} to={link}>
          {label}
        </Nav.Link>
      ) : (
        <Nav.Link onClick={onClick}>
          {label}
        </Nav.Link>
      )}
    </Navbar.Toggle>
  </Nav.Item>
);

const Header = ( { accounts, profile, projects }:
{ projects: any[]; accounts: any[]; profile?: Profile } ) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const userAccountId = useSelector( selectUserAccount );
  const userRole = useSelector( selectUserRole );
  const userAccount = _.find( accounts, { cuentaDeInversionId: userAccountId } );
  const myAccounts: any = _.filter( accounts, { miInversion: true } );
  const otherAccounts: any = _.filter( accounts, { miInversion: false } );

  const logout = useCallback( () => {
    dispatch( logoutUser() );
    Storage.clear( 'grupoimpar:token' );
  }, [] );

  return (
    <Navbar expand="md" bg="dark" className="py-0">
      <Container>
        <Navbar.Brand className="p-0 cursor-pointer" onClick={() => history.push( '/' )}>
          <img src={logo} alt="Grupo Impar" height={40} />
        </Navbar.Brand>

        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {userRole === 'Admin'
          && (
            <a
              className="text--medium btn btn-outline-warning nav__item--btn-admin d-md-block d-none"
              href={ADMIN_ACCOUNTS}
              rel="noreferrer"
              target="_blank"
            >
              ADMIN
            </a>
          )}
          {!!myAccounts && myAccounts.length && (
            <Nav className="nav__item-investment d-none d-md-block">
              <NavDropdown
                title={userAccount?.nameCuentadeInversion?.length > 22 ? `${userAccount?.nameCuentadeInversion?.slice( 0, 22 )}...` : userAccount?.nameCuentadeInversion}
                id="investments-nav-dropdown"
              >
                {myAccounts.map( ( item: any ) => {
                  if ( item.cuentaDeInversionId !== userAccountId ) {
                    return (
                      <NavDropdown.Item
                        key={item.cuentaDeInversionId}
                        onClick={() => {
                          dispatch( updateUserAccount( item.cuentaDeInversionId ) );
                          history.replace( `${DASHBOARD}` );
                        }}
                      >
                        {item.nameCuentadeInversion.length > 22
                          ? `${item.nameCuentadeInversion.slice( 0, 22 )}...` : item.nameCuentadeInversion}
                      </NavDropdown.Item>
                    );
                  }
                  return null;
                } )}
                {!!otherAccounts && otherAccounts.length && ( <NavDropdown.Divider /> )}
                {otherAccounts.map( ( item: any ) => {
                  if ( item.cuentaDeInversionId !== userAccountId ) {
                    return (
                      <NavDropdown.Item
                        key={item.cuentaDeInversionId}
                        onClick={() => {
                          dispatch( updateUserAccount( item.cuentaDeInversionId ) );
                          history.replace( `${DASHBOARD}` );
                        }}
                      >
                        {item.nameCuentadeInversion.length > 22
                          ? `${item.nameCuentadeInversion.slice( 0, 22 )}...` : item.nameCuentadeInversion}
                      </NavDropdown.Item>
                    );
                  }
                  return null;
                } )}
              </NavDropdown>
            </Nav>
          )}

          <Nav className="nav__item-user d-none d-md-block">
            <NavDropdown
              title={(
                <>
                  {profile?.photo ? ( <img src={profile?.photo} alt="" className="rounded-circle avatar--xxs me-3" /> )
                    : (
                      <img src={userImg} alt="..." className="app-avatar1" />
                    )}
                  <span>
                    {`${profile?.firstName || ''} ${profile?.lastName || ''}`}
                  </span>
                </>
              )}
              id="user-nav-dropdown"
            >
              <NavDropdown.Item
                as={NavLink}
                to={PROFILE}
                activeClassName=""
              >
                {t( 'personalArea' )}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                className="text-primary"
                onClick={logout}
              >
                {t( 'closeSession' )}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>

          <Nav className="nav__item-mobile d-md-none">
            <div className="overlay" />
            <div className="nav__item-mobile__content">
              <div className="text--right">
                <Navbar.Toggle className="p-0">
                  <i className="icon-cancel text--xmd text--gray12" />
                </Navbar.Toggle>
              </div>
              <div className="py-3">
                <img src={img} alt="" className="rounded-circle avatar me-3" />
                {!!profile && (
                  <span className="text--xmd text--bold">{`${profile?.firstName} ${profile?.lastName}`}</span> )}

                <Nav.Item>
                  <Accordion className="accordion-account">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header as="span">
                        {userAccount?.nameCuentadeInversion?.length > 22 ? `${userAccount?.nameCuentadeInversion?.slice( 0, 22 )}...` : userAccount?.nameCuentadeInversion}
                      </Accordion.Header>
                      <Accordion.Body>
                        {accounts.map( ( item ) => {
                          if ( item.cuentaDeInversionId !== userAccountId ) {
                            return (
                              <NavItemMobile
                                label={item.nameCuentadeInversion.length > 22
                                  ? `${item.nameCuentadeInversion.slice( 0, 22 )}...` : item.nameCuentadeInversion}
                                key={item.cuentaDeInversionId}
                                onClick={() => {
                                  dispatch( updateUserAccount( item.cuentaDeInversionId ) );
                                }}
                              />
                            );
                          }
                          return null;
                        } )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Nav.Item>
              </div>

              <NavItemMobile
                label={t( 'globalPosition' )}
                link={DASHBOARD}
              />

              {!!projects.length
              && (
              <Nav.Item>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header as="span">{t( 'projectsMenu' )}</Accordion.Header>
                    <Accordion.Body>
                      {projects.map( ( item ) => (
                        <NavItemMobile
                          key={item.promocionId}
                          label={item.codigoPromocion}
                          link={`${PROJECTS}/${item.promocionId}/${convertToSlug( item.codigoPromocion )}`}
                        />
                      ) )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Nav.Item>
              )}
              <NavItemMobile
                label={t( 'historic' )}
                link={HISTORIC}
              />
              {/* <NavItemMobile
                label={t( 'newsMenu' )}
                link={NEWS}
              /> */}
              <NavItemMobile
                label={t( 'personalArea' )}
                link={PROFILE}
              />
              <Nav.Item>
                <Nav.Link className="text--sm text-primary">
                  {t( 'closeSession' )}
                </Nav.Link>
              </Nav.Item>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
