import React, { useState } from 'react';
import AdminContainer from 'components/Admin/AdminContainer';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import validateForm from 'utils/Validator';
import useSubmit from 'hooks/useSubmit';
import AuthService from 'services/Auth';
import SuccessMessage from 'components/Modal/SuccessMessage';
import Form from 'components/Form/Layout';
import PasswordInput from 'components/PasswordInput';
import CardLight from 'components/CardLight';
import { yupChangeOldPassword } from 'utils/yupFields';

const ChangePassword = () => {
  const { t } = useTranslation();
  const { id, type }:{ id:any; type:any } = useParams();
  const location = useLocation();
  const dataLocation:any = location?.state;

  const [formSubmitted, setFormSubmitted] = useState( false );
  const validationSchema = yup.object( yupChangeOldPassword( t ) );
  const validate = validateForm( validationSchema );

  const submitForm = useSubmit( {
    promise: ( formData ) => AuthService.changePasswordByAdmin( { ...formData, userId: id } ),
    callback: () => setFormSubmitted( true ),
    showAlert: true,
  } );

  let headerTitle = type === 'inv' ? dataLocation?.nameCuentadeInversion : '';
  headerTitle = type === 'user' ? dataLocation?.fullName : headerTitle;
  headerTitle = type === 'pro' ? dataLocation?.codigoPromocion : headerTitle;

  return (
    <AdminContainer
      credentialState={dataLocation?.credentialState}
      extraData={dataLocation?.extraData}
      headerTitle={headerTitle}
      type={type}
      id={id}
    >
      <CardLight>
        <Row>
          <Col md={4}>
            {formSubmitted
              ? (
                <SuccessMessage description={t( 'profile.changePassSuccess' )} onClose={() => {}} />
              )
              : (
                <>
                  <Form
                    initialValues={{}}
                    validate={validate}
                    onSubmit={submitForm}
                    body={(
                      <div>
                        <Row className="mt-4">
                          <Col>
                            <PasswordInput
                              field="password"
                              label="profile.password"
                              classNames={{ formGroup: 'mb-2' }}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col>
                            <PasswordInput
                              field="confirmPassword"
                              label="profile.confirmPassword"
                              classNames={{ formGroup: 'mb-2' }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-4">
                            <Button
                              variant="dark"
                              type="submit"
                              size="sm"
                              className="btn--full-width"
                            >
                              {t( 'confirm' )}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                        )}
                  />
                </>
              )}
          </Col>
        </Row>
      </CardLight>
    </AdminContainer>
  );
};

export default ChangePassword;
