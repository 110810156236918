import React from 'react';
import numeral from 'numeral';
import { formatCurrencyNoDecimal, formatCurrencyNoDecimalAbbrev } from 'utils/formats';

type MillionNumeralType = {
  number?: number | string;
};

const MillionNumeral = ( { number }: MillionNumeralType ) => (
  <>
    <span className="d-md-none">
      {number && number.toString().length >= 7
        ? numeral( number )
          .format( formatCurrencyNoDecimalAbbrev )
        : numeral( number ).format( formatCurrencyNoDecimal )}
    </span>
    <span className="d-none d-md-block">
      {numeral( number )
        .format( formatCurrencyNoDecimal )}
    </span>
  </>
);

export default MillionNumeral;
