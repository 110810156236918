import React, { ComponentType, useContext } from 'react';
import {
  Redirect, Route, RouteProps, Switch,
} from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './i18n';
import UserLayout from 'screens/User/Layout';
import AdminLayout from 'screens/Admin/Layout';
import Login from 'screens/Login';
import RecoverPassword from 'screens/RecoverPassword';
import { LoadingContext } from 'context/Loading';
import { ModalErrorContext } from 'context/ModalError';
import NotificationModal from 'components/Modal/NotificationModal';
import { selectUser } from 'store/User';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import ForceChangePassword from './screens/ForceChangePassword';

type CustomRouteProps = {
  isLoggedIn?: boolean,
  component: ComponentType<any>
} & RouteProps;

numeral.register( 'locale', 'es', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal( number ) {
    return number === 1 ? 'er' : 'ero';
  },
  currency: {
    symbol: '€',
  },
} );
numeral.locale( 'es' );

const PrivateRoute = ( { component: Component, isLoggedIn, ...rest }: CustomRouteProps ) => (
  <Route
    {...rest}
    render={( props ) => (
      isLoggedIn
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login' }} />
    )}
  />
);

const PublicRoute = ( { component: Component, isLoggedIn, ...rest }: CustomRouteProps ) => (
  <Route
    {...rest}
    render={( props ) => (
      !isLoggedIn
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/' }} />
    )}
  />
);

function App() {
  const { t } = useTranslation();
  const { loading } = useContext( LoadingContext );
  const { description, showModal, closeErrorModal } = useContext( ModalErrorContext );
  const user = useSelector( selectUser );
  const isLoggedIn = !!( user?.accessToken && user?.account );

  return (
    <>
      <Switch>
        <PublicRoute path="/login" component={Login} isLoggedIn={isLoggedIn} />
        <PublicRoute path="/recover-password" component={RecoverPassword} isLoggedIn={isLoggedIn} />
        <PublicRoute path="/force-change-password" component={ForceChangePassword} isLoggedIn={isLoggedIn} />
        {user?.role === 'Admin' ? <PrivateRoute path="/admin" component={AdminLayout} isLoggedIn={isLoggedIn} /> : null}
        <PrivateRoute path="/" component={UserLayout} isLoggedIn={isLoggedIn} />
      </Switch>

      {loading
      && (
        <div className="app-loading">
          <Spinner
            animation="border"
            variant="primary"
            style={{
              width: '3rem',
              height: '3rem',
            }}
          />
        </div>
      )}

      <NotificationModal
        onClose={closeErrorModal}
        description={description}
        title={t( 'hasError' )}
        show={showModal}
        success={false}
      />
    </>
  );
}

export default App;
