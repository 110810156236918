import React, { useCallback, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import TextField from 'components/Form/TextField';
import { DTable } from 'types/table';
import { useHistory } from 'react-router';
import { CellProps } from 'react-table';
import numeral from 'numeral';
import iconDownload from 'assets/img/icons/download.svg';
import { formatCurrencyNoDecimal, formatPercentOneDecimal } from 'utils/formats';
import { updateUserAccount } from 'store/User';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

const Account = ( {
  accounts, fetchData, isLoading, downloadDocument, type, updateCrm,
  updateSharedPoint,
}:{
  isLoading?:boolean;
  accounts:DTable;
  fetchData: ( params:any, pageSize:number )=>void ;
  type?: string;
  downloadDocument: ( params:any )=>void ;
  updateCrm?: ( )=>void ;
  updateSharedPoint?: ( )=>void ;
} ) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [criteria, setCriteria] = useState( '' );

  const debounceSearch = useCallback( _.debounce( ( fn ) => fn(), 500 ), [] );

  let defaultSortBy = [{ id: 'capitalActualInvertido', desc: true }];
  if ( type === 'user' ) {
    defaultSortBy = [{ id: 'capitalActualInvertido', desc: true }];
  }
  if ( type === 'pro' ) {
    defaultSortBy = [{ id: 'capitalActualInvertido', desc: true }];
  }
  return (
    <>
      <Table
        columns={[
          {
            Header: t( 'admin.account.header1' ),
            accessor: 'nameCuentadeInversion',
            headerClassName: 'text--left',
            className: 'text--bold',
            Cell: ( { cell }: CellProps<any> ) => (
              <span
                className="text--bold cursor-pointer"
                onClick={() => {
                  history.push( `/admin/accounts/${cell.row.original.cuentaDeInversionId}/projects/inv`,
                    { nameCuentadeInversion: cell.row.original.nameCuentadeInversion } );
                }}
              >
                {`${cell.value}`}
              </span>
            ),
          },
          {
            Header: !type ? t( 'admin.account.header2' ) : t( 'admin.account.header21' ),
            className: 'text--right text--lato',
            headerClassName: 'text--right',
            accessor: 'capitalActualInvertido',
            customWidth: 180,
            Cell: ( { cell }: CellProps<any> ) => (
              numeral( cell.value ).format( formatCurrencyNoDecimal ) ),
          },
          {
            accessor: 'beneficiosEstimados',
            className: 'text--right text--lato',
            headerClassName: 'text--right ',
            Header: `${t( 'admin.account.header3' )}*`,
            customWidth: 180,
            Cell: ( { cell }: CellProps<any> ) => (
              numeral( cell.value ).format( formatCurrencyNoDecimal ) ),
          },
          {
            Header: `${t( 'admin.account.header4' )}*`,
            accessor: 'roiMedioEstimado',
            headerClassName: 'text--right',
            className: 'text--right text--lato',
            customWidth: 145,
            Cell: ( { cell }: CellProps<any> ) => (
              numeral( cell.value / 100 ).format( formatPercentOneDecimal ) ),
          },
          {
            Header: `${t( 'admin.account.header5' )}*`,
            accessor: 'tirMediaEstimado',
            className: 'text--right text--lato',
            headerClassName: 'text--right',
            customWidth: 145,
            Cell: ( { cell }: CellProps<any> ) => (
              numeral( cell.value / 100 ).format( formatPercentOneDecimal ) ),
          },
          {
            Header: t( 'admin.account.header6' ),
            accessor: 'item5',
            className: 'text--right',
            disableSortBy: true,
            customWidth: 80,
            Cell: ( { cell }:CellProps<any> ) => (
              <Button
                className="p-0"
                variant="link"
                onClick={() => {
                  dispatch( updateUserAccount( cell.row.original.cuentaDeInversionId ) );
                  window.open( '/', '_blank' );
                }}
              >
                <i className="icon-eye-line text-primary icon--xs" />
              </Button>
            ),
            headerClassName: 'text--right',
          },
        ]}
        defaultSortBy={defaultSortBy}
        isLoading={isLoading}
        data={accounts.data}
        total={accounts.totalItems}
        pageCount={accounts.pageCount}
        fetchData={fetchData}
        specialText2={t( 'successFee' )}
        header={( filters, setFilters ) => (
          <Row className="align-items-center">
            <Col lg={9} xs={6} sm={8}>
              <Button
                size="sm"
                className="pt-2 pb-2 ps-4 pe-4 text--normal btn--rounded d-flex align-items-center"
                onClick={() => downloadDocument( { download: true } )}
              >
                <div>
                  <img src={iconDownload} alt="" className="icon--img" />
                  {t( 'admin.common.download' )}
                </div>
              </Button>
            </Col>
            <Col lg={3} xs={6} sm={4}>
              <TextField
                value={criteria}
                showErrors={false}
                classNames={{ formGroup: 'input-filters' }}
                appendIcon="icon-search text-primary"
                onChange={( value ) => {
                  setCriteria( value );
                  debounceSearch( () => setFilters( ['nameCuentadeInversion'], value ) );
                }}
                placeholder="search"
              />
            </Col>
          </Row>
        )}
      />
      <Row className="mt-5">
        <Col className="" />
        <Col md={3} className="text--right">
          <Button
            size="sm"
            className="pt-2 pb-2 text--normal btn--rounded"
            onClick={updateCrm}
          >
            <div>
              {t( 'admin.common.updatecrm' )}
            </div>
          </Button>
        </Col>
        <Col md={3} className="text--right">
          <Button
            size="sm"
            className="pt-2 pb-2 text--normal btn--rounded"
            onClick={updateSharedPoint}
          >
            <div>
              {t( 'admin.common.updateshared' )}
            </div>
          </Button>
        </Col>
      </Row>

    </>
  );
};

export default Account;
