import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from 'components/Admin/Header';
import Accounts from 'screens/Admin/Accounts';
import Investors from 'screens/Admin/Investors';
import Projects from 'screens/Admin/Projects';
import {
  ADMIN_ACCOUNT_INVESTORS,
  ADMIN_ACCOUNT_PROJECTS,
  ADMIN_ACCOUNTS,
  ADMIN_INVESTOR_ACCOUNTS, ADMIN_INVESTOR_CHANGE_PASSWORD,
  ADMIN_INVESTOR_PROJECTS,
  ADMIN_INVESTORS, ADMIN_PROFILE, ADMIN_PROJECT_ACCOUNTS, ADMIN_PROJECT_INVESTORS,
  ADMIN_PROJECTS,
} from 'constants/routes';
import handlePromise from 'utils/Promise';
import ProfileService from 'services/Profile';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import ChangePassword from '../Investors/ChangePassword/ChangePassword';
import Profile from '../../User/Profile';

const AdminLayout = () => {
  const [profile, setProfile] = useState<any>();
  const history = useHistory();
  const [readyLayout, setReadyLayout] = useState<boolean>( false );
  const getProfile = useCallback( async () => {
    const [, profileResponse, profileData] = await handlePromise( ProfileService.getProfile() );
    if ( profileData.isNew ) {
      history.push( '/force-change-password' );
      return;
    }
    if ( profileResponse.ok ) setProfile( profileData );
    setTimeout( () => {
      setReadyLayout( true );
    }, 800 );
  }, [] );

  useEffect( () => {
    getProfile();
  }, [] );

  return (
    <>
      <Header profile={profile} />
      {readyLayout ? (
        <Switch>
          <Route path={ADMIN_ACCOUNT_PROJECTS} component={Projects} />
          <Route path={ADMIN_ACCOUNT_INVESTORS} component={Investors} />
          <Route path={ADMIN_ACCOUNTS} exact component={Accounts} />
          <Route path={ADMIN_INVESTOR_ACCOUNTS} component={Accounts} />
          <Route path={ADMIN_INVESTOR_PROJECTS} component={Projects} />
          <Route path={ADMIN_INVESTOR_CHANGE_PASSWORD} component={ChangePassword} />
          <Route path={ADMIN_INVESTORS} component={Investors} />
          <Route path={ADMIN_PROJECT_ACCOUNTS} component={Accounts} />
          <Route path={ADMIN_PROJECT_INVESTORS} component={Investors} />
          <Route path={ADMIN_PROFILE} component={Profile} />
          <Route path={ADMIN_PROJECTS} component={Projects} />
          <Redirect from="*" to="/" />
        </Switch>
      )
        : (
          <div className="app-loading">
            <Spinner
              animation="border"
              variant="primary"
              style={{
                width: '3rem',
                height: '3rem',
              }}
            />
          </div>
        )}
    </>
  );
};

export default AdminLayout;
