import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import Detail from './Detail';

const News = ( { match }: { match: any } ) => (
  <Switch>
    <Route path={`${match.url}/:id`} component={Detail} />
    <Route path={match.url} exact component={Dashboard} />
  </Switch>
);

export default News;
