import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import TabsHeader from '../TabsHeader';
import BodyContent from '../../BodyContent';

type AdminContainerProps = {
  children: ReactNode;
  headerTitle?: string;
  id?: number;
  type?: string;
  credentialState?: number;
  extraData?: any;
};

const AdminContainer = ( {
  children, headerTitle, type, id, credentialState, extraData = null,
}: AdminContainerProps ) => (
  <>
    <TabsHeader
      title={headerTitle}
      type={type}
      id={id}
      credentialState={credentialState}
      extraData={extraData}
    />
    <Container fluid="lg" className="body-container">
      <BodyContent>
        {children}
      </BodyContent>
    </Container>
  </>
);

export default AdminContainer;
