import React, {
  ReactNode, useEffect, useMemo, useState,
} from 'react';
import {
  useTable, usePagination, useSortBy, useFilters,
} from 'react-table';
import classnames from 'classnames';
import CardLight from 'components/CardLight';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import numeral from 'numeral';
import { useHistory } from 'react-router';
import { convertToSlug } from 'utils/utils';
import Pagination from '../Pagination';
import { formatCurrencyNoDecimal, formatPercent } from '../../utils/formats';

type TableProps = {
  data: any[],
  defaultPageSize?: number,
  pageCount?: number,
  isLoading?: boolean,
  paginationOptions?: string[]
  specialText?: string
  fetchData: ( params: any, pageSize:number ) => void,
  header: ( filters: any, setFilters: ( fields: string[], value: any ) => void ) => ReactNode
};

const RMobileTable = ( {
  data, defaultPageSize = 10, fetchData,
  header, isLoading, pageCount, specialText,
  paginationOptions = ['10', '20', '30'],
}: TableProps ) => {
  const { t } = useTranslation();
  const [filters, setFilterDefault] = useState<any>( {} );
  const history = useHistory();

  const tableInstance = useTable( {
    columns: useMemo( () => [{
      accessor: 'promocionId',
    }], [] ),
    data: useMemo( () => data, [data] ),
    initialState: { pageSize: defaultPageSize },
    pageCount,
    manualFilters: true,
    manualPagination: true,
    manualSortBy: true,
  }, useFilters, useSortBy, usePagination );

  const {
    page,
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, sortBy, pageSize },
  } = tableInstance;

  const setFilter = ( fields: string[], value: any ) => {
    if ( pageIndex > 0 ) gotoPage( 0 );
    let temp: any = {};
    fields.forEach( ( item ) => {
      temp = { ...temp, [item]: value };
    } );
    setFilterDefault( { ...filters, ...temp } );
  };

  useEffect( () => {
    const apiParams: any = {
      Page: pageIndex + 1,
      ItemsPerPage: pageSize,
    };
    if ( sortBy && sortBy.length ) apiParams.sortDirection = `${sortBy[0].id}##${sortBy[0].desc ? 'Descending' : 'Ascending'}`;
    if ( filters ) {
      const result:any[] = [];
      Object.keys( filters ).forEach( ( key: string ) => {
        if ( filters[key] ) {
          result.push( `${key}##${filters[key]}` );
        }
      } );
      if ( result.length ) apiParams.filter = result.join( '@@' );
    }
    fetchData( apiParams, pageSize );
  }, [fetchData, pageSize, sortBy, filters, pageIndex] );

  return (
    <div className="position-relative">
      <div className={classnames( 'divTable--block', {
        'app-component-loading': isLoading,
      } )}
      >
        <div className="mb-3">
          {header && header( filters, setFilter )}
        </div>

        {page.length ? page.map( ( row ) => {
          const dataOriginal:any = row.original;
          return (
            <div key={row.id} className="historic__card-mobile mt-4 ">
              <CardLight
                title={dataOriginal.codigoPromocion}
                onClick={() => {
                  if ( dataOriginal.tipoDeProyecto === 'Fondo' ) {
                    history.push( `/investment-vehicle/${dataOriginal.promocionId}/${convertToSlug( dataOriginal.codigoPromocion )}` );
                  } else {
                    history.push( `/projects/${dataOriginal.promocionId}/${convertToSlug( dataOriginal.codigoPromocion )}` );
                  }
                }}
              >
                <Row>
                  <Col xs={3}>
                    <div className="text--gray8 text--bold">{t( 'inicio' ).toUpperCase()}</div>
                    <div className="text--bold">{dataOriginal.inicio}</div>
                  </Col>
                  <Col xs={4}>
                    <div className="text--gray8 text--bold">{t( 'fin' ).toUpperCase()}</div>
                    <div className="text--bold">{dataOriginal.fin}</div>
                  </Col>
                  <Col xs="auto">
                    <div className="text--gray8 text--bold">{t( 'capital' ).toUpperCase()}</div>
                    <div className="text--bold">
                      {numeral( dataOriginal.capitalInvertido )
                        .format( formatCurrencyNoDecimal )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={3}>
                    <div className="text--gray8 text--bold">
                      {t( 'roi' ).toUpperCase()}
                      *
                    </div>
                    <div className="text--bold">{numeral( dataOriginal.roi / 100 ).format( formatPercent ) }</div>
                  </Col>
                  <Col xs={4}>
                    <div className="text--gray8 text--bold">
                      {t( 'tir' ).toUpperCase()}
                      *
                    </div>
                    <div className="text--bold">{numeral( dataOriginal.tir / 100 ).format( formatPercent ) }</div>
                  </Col>
                  <Col xs="auto">
                    <div className="text--gray8 text--bold">
                      {t( 'profit' ).toUpperCase()}
                      *
                    </div>
                    <div className="text--bold">{numeral( dataOriginal.beneficio ).format( formatCurrencyNoDecimal )}</div>
                  </Col>
                </Row>
                {!!specialText && (
                <p className="text--xxs text--gray8 m-0 text--lato mt-2">{specialText}</p>
                )}
              </CardLight>
            </div>
          );
        } ) : (
          <CardLight
            title={t( 'noTableData' )}
          >
            <></>
          </CardLight>
        )}

        <Pagination
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          pageCount={pageCount}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          fullText={false}
          options={paginationOptions}
        />
      </div>
    </div>
  );
};

export default RMobileTable;
