import React, { useEffect, useState } from 'react';
import {
  Button, Col, Modal, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as yup from 'yup';
import validateForm from 'utils/Validator';
import ContactService from 'services/Contact';
import useSubmit from 'hooks/useSubmit';
import TextField from 'components/Form/FormTextField';
import Form from 'components/Form/Layout';
import Select from 'components/Form/FormSelect';
import SuccessMessage from '../SuccessMessage';

type ContactModalProps = {
  show: boolean,
  data: any,
  onClose: () => void,
};

const ContactModal = ( { show, onClose, data }: ContactModalProps ) => {
  const { t } = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState( false );
  const validationSchema = yup.object( {
    asunto: yup.string().required(),
    categoriaId: yup.string().required(),
    mensaje: yup.string().required(),
  } );
  const validate = validateForm( validationSchema );
  const cats = _.map( data?.categorias, ( item ) => ( {
    id: item.id.toString(),
    name: item.descripcion,
  } ) );

  const submitForm = useSubmit( {
    promise: ( formData ) => ContactService.postContact( formData ),
    callback: () => setFormSubmitted( true ),
    showAlert: false,
  } );

  useEffect( () => {
    if ( !show ) setFormSubmitted( false );
  }, [show] );

  return (
    <Modal
      show={show}
      className="modal-carousel"
      centered
      onHide={onClose}
    >
      <Modal.Header className="pb-0" closeButton />
      <Modal.Body className="pt-0">
        <Row>
          <Col md={10} className="m-auto">
            <h3 className="text-center mb-4">Contactar</h3>
            {formSubmitted
              ? (
                <SuccessMessage description={t( 'profile.successContact' )} onClose={onClose} />
              ) : (
                <>
                  <div className="info-card--light">
                    <p className="text--xs m-0">
                      {data?.titulo}
                    </p>
                  </div>
                  <Form
                    validate={validate}
                    onSubmit={submitForm}
                    body={(
                      <div className="pt-4">
                        <Row>
                          <Col className="mt-1">
                            <TextField
                              field="asunto"
                              label="profile.subject"
                              classNames={{ formGroup: 'mb-2' }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-4">
                            <Select
                              options={cats}
                              field="categoriaId"
                              label="profile.category"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <TextField
                              as="textarea"
                              field="mensaje"
                              label="profile.message"
                              classNames={{ formGroup: 'mb-2' }}
                              style={{ height: '130px' }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-4">
                            <Button
                              variant="primary"
                              type="submit"
                              className="btn--full-width"
                            >
                              {t( 'send' )}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                          )}
                  />
                </>
              )}

          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;
