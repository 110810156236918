import React, {
  createContext, ReactNode, useCallback, useState,
} from 'react';

export const ModalErrorContext = createContext( {
  showModal: false,
  description: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openErrorModal: ( description: string ) => {},
  closeErrorModal: () => {},
} );

const ModalErrorProvider = ( { children }: { children: ReactNode } ) => {
  const [showModal, setShowModal] = useState( false );
  const [description, setDescription] = useState( '' );

  const openErrorModal = useCallback( ( desc ) => {
    setDescription( desc );
    setShowModal( true );
  }, [] );

  const closeErrorModal = useCallback( () => {
    setDescription( '' );
    setShowModal( false );
  }, [] );

  return (
    <ModalErrorContext.Provider value={{
      description,
      showModal,
      openErrorModal,
      closeErrorModal,
    }}
    >
      {children}
    </ModalErrorContext.Provider>
  );
};

export default ModalErrorProvider;
