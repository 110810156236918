import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import FundsView from 'views/User/Funds';
import { useSelector } from 'react-redux';
import { selectUserAccount } from 'store/User';
import handlePromise from 'utils/Promise';
import ProjectService from 'services/Projects';
import { KpiResumeProject } from 'types/Project';
import { useParams } from 'react-router-dom';
import { getFile, getFileFromBase64 } from 'utils/downloadBase64';
import { LoadingContext } from 'context/Loading';

const Funds = () => {
  const { id }:{ id:any; type:any } = useParams();
  const userAccountId = useSelector( selectUserAccount );
  const { toggleLoading } = useContext( LoadingContext );
  const [kpiProject, setKpiProject] = useState<KpiResumeProject | null>( null );
  const [documents, setDocuments] = useState<[] | undefined>( );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [documentsInversion, setDocumentsInversion] = useState<[] | undefined>( );

  const downloadFilePdf = useCallback( async ( ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getInformePdfByPromocion( userAccountId, id, { responseType: 'blob' } ),
    );

    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFile( downloadDocumentData, `${kpiProject?.codigoPromocion}-informe-por-promoción`, 'application/pdf', 'pdf' );
    }
  }, [userAccountId, id, kpiProject] );

  const downloadFiles = useCallback( async ( ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getUltimoInformeFinancieroPromocion( userAccountId, id ),
    );
    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFileFromBase64( downloadDocumentData, `${kpiProject?.codigoPromocion}_ultimo-informe-financiero` );
    }
  }, [userAccountId, id, kpiProject] );

  const getData = useCallback( async () => {
    const [, kpiProjectResponse, kpiProjectData] = await handlePromise(
      ProjectService.getKPIPromocionById( userAccountId, id ),
    );
    if ( kpiProjectResponse.ok ) setKpiProject( kpiProjectData );

    setDocuments( undefined );
    const [, documentProjectResponse, documentProjectData] = await handlePromise(
      ProjectService.getDocumentacion( userAccountId, id ),
    );
    if ( documentProjectResponse.ok ) setDocuments( documentProjectData );

    setDocumentsInversion( undefined );
    const [, documentInversionResponse, documentInversionData] = await handlePromise(
      ProjectService.getDocumentacionInversion( userAccountId, id ),
    );
    if ( documentInversionResponse.ok ) setDocumentsInversion( documentInversionData );
  }, [userAccountId, id] );

  useEffect( () => {
    getData();
  }, [userAccountId, id] );

  return (
    <>
      <FundsView
        kpiProject={kpiProject}
        documents={documents}
        documentsInversion={documentsInversion}
        downloadFile={downloadFiles}
        downloadFilePdf={downloadFilePdf}
      />
    </>
  );
};

export default Funds;
