import React from 'react';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DarkTile from 'components/DarkTile';
import LightTile from 'components/LightTile';
import BodyContent from 'components/BodyContent';
import icon1 from 'assets/img/dashboard-icon3.svg';
import icon2 from 'assets/img/dashboard-icon2.svg';
import icon3 from 'assets/img/project-icon1.svg';
import icon6 from 'assets/img/project-icon4.svg';
import icon9 from 'assets/img/project-icon7.svg';
import icon10 from 'assets/img/project-icon8.svg';
import icon16 from 'assets/img/dashboard-icon6.svg';
import icon17 from 'assets/img/project-icon14.svg';
import icon18 from 'assets/img/project-icon12.svg';
import icon19 from 'assets/img/project-icon13.svg';
import icon20 from 'assets/img/project-icon15.svg';
import icon21 from 'assets/img/project-icon16.svg';
import icon22 from 'assets/img/project-icon17.svg';
import icon23 from 'assets/img/project-icon18.svg';
import icon15 from 'assets/img/dashboard-icon5.svg';

import { KpiResumeProject } from 'types/Project';
import numeral from 'numeral';
import { formatPercent, formatPercentOneDecimal } from 'utils/formats';
import Documents from 'components/Dashboard/Documents';
import MillionNumeral from 'components/MillionNumeral';

type FundsProps = {
  downloadFile: ( ) => void;
  downloadFilePdf: ( ) => void;
  kpiProject: KpiResumeProject | null ;
  documents: any[] | undefined ;
  documentsInversion: any[] | undefined ;
};

const Funds = ( {
  kpiProject, documents, documentsInversion,
  downloadFile, downloadFilePdf,
}:FundsProps ) => {
  const { t } = useTranslation();

  return (
    <BodyContent
      showTitleInTablet
      title={(
        <div className="px-3 pt-3 pb-4 p-md-0 pb-md-4">
          <Row className="d-flex justify-content-md-between">
            <Col md={6}>
              <h1 className="mb-2">
                {kpiProject?.codigoPromocion}
              </h1>
            </Col>
            {kpiProject ? (
              <Col md={6} className="d-none d-lg-block text--right">
                {kpiProject.informeFinanciero && (
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="py-2 text--normal btn--rounded"
                  onClick={downloadFile}
                >
                  <div>
                    {t( 'lastReport' )}
                  </div>
                </Button>
                )}
                <Button
                  size="sm"
                  className="py-2 text--normal btn--rounded ms-2 px-5"
                  onClick={downloadFilePdf}
                >
                  <div className="px-2">
                    {t( 'movements' )}
                  </div>
                </Button>
              </Col>
            ) : null}

          </Row>
          {!!kpiProject?.descripcion && (
          <p className="mb-0" dangerouslySetInnerHTML={{ __html: `${kpiProject?.descripcion?.toString().replaceAll( '\n', '<br />' )}` }} />
          )}
          {kpiProject ? (
            <Row className="mt-2 d-lg-none">
              <Col md="auto" className="text-center text-md-start">
                {kpiProject.informeFinanciero && (
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="py-2 text--normal btn--rounded mt-4 mt-md-2"
                  onClick={downloadFile}
                >
                  <div>
                    {t( 'lastReport' )}
                  </div>
                </Button>
                )}
              </Col>
              <Col md="auto" className="text-center text-md-start">
                <Button
                  size="sm"
                  className="py-2 text--normal btn--rounded  px-5 mt-4 mt-md-2"
                  onClick={downloadFilePdf}
                >
                  <div className="px-2">
                    {t( 'movements' )}
                  </div>
                </Button>
              </Col>
            </Row>
          ) : null}
        </div>
      )}
    >
      <div className="row g-3 g-md-4 mb-4">
        <DarkTile
          icon={icon1}
          title={( <MillionNumeral number={kpiProject?.miCapitalInvertido} /> )}
          subtitle={t( 'projects.myInvestedCapital' )}
          className="lg-auto"
        />
        <DarkTile
          icon={icon18}
          title={( <MillionNumeral number={kpiProject?.capitalInvertido} /> )}
          subtitle={t( 'projects.committedCapital' )}
          className="lg-auto"
        />
        <DarkTile
          icon={icon2}
          title={( <MillionNumeral number={kpiProject?.beneficioEstimado} /> )}
          subtitle={t( 'projects.myEstimatedProfit' )}
          subtitle2={t( 'beforeFee3' )}
          className="lg-auto"
        />
        <DarkTile
          icon={icon15}
          title={( <MillionNumeral number={kpiProject?.repartosRecibidos} /> )}
          subtitle={t( 'projects.deliveriesReceived' )}
          className="lg-auto"
        />
        <DarkTile
          icon={icon16}
          title={( <MillionNumeral number={kpiProject?.proximosRepartos} /> )}
          subtitle={t( 'projects.upcomingDeliveries' )}
          subtitle2={kpiProject?.antesFeeExito ? t( 'beforeFee3' ) : t( 'beforeFee2' )}
          className="lg-auto"
        />
      </div>

      <h3 className="text--lg mb-4">{t( 'projects.foundOverview' )}</h3>

      <div className="row align-items-stretch row-cols-xl-5 row-cols-md-3 row-cols-2 g-3 g-md-4 mb-4">
        <LightTile
          icon={icon3}
          title={( <MillionNumeral number={kpiProject?.capitalTotal} /> )}
          subtitle={t( 'Equity' )}
        />
        <LightTile
          icon={icon19}
          title={numeral( kpiProject?.roiEstimado
            ? kpiProject?.roiEstimado / 100 : 0 ).format( formatPercentOneDecimal )}
          subtitle={t( 'ROE estimado' )}
        />
        <LightTile
          icon={icon6}
          title={numeral( kpiProject?.tirEstimado
            ? kpiProject?.tirEstimado / 100 : 0 ).format( formatPercentOneDecimal )}
          subtitle={t( 'TIR estimado' )}
        />
        <LightTile
          icon={icon9}
          title={`${kpiProject?.plazoInversion
          && kpiProject?.plazoInversion > 0 ? kpiProject?.plazoInversion / 12 : ''} ${kpiProject?.plazoInversion ? t( 'años' ) : ''}`}
          subtitle={t( 'Plazo de Inversión' )}
        />

        <LightTile
          icon={icon17}
          title={`${kpiProject?.trimFin ? kpiProject?.trimFin?.toString().toLowerCase() : ''} `}
          subtitle={t( 'Trim. Fin' )}
        />
        <LightTile
          icon={icon10}
          title={numeral( kpiProject?.ltv ? kpiProject?.ltv / 100 : 0 ).format( formatPercent )}
          subtitle={t( 'LTV' )}
        />

        <LightTile
          icon={icon20}
          title={numeral( kpiProject?.rentabilidadSobreCostes
            ? kpiProject?.rentabilidadSobreCostes / 100 : 0 ).format( formatPercent )}
          subtitle={t( 'profitabilityOnCosts' )}
        />
        <LightTile
          icon={icon21}
          title={numeral( kpiProject?.rentabilidadDeSalida
            ? kpiProject?.rentabilidadDeSalida / 100 : 0 ).format( formatPercent )}
          subtitle={t( 'exitProfitability' )}
        />

        <LightTile
          icon={icon22}
          title={`${kpiProject?.plazoDeExplotacion ? kpiProject?.plazoDeExplotacion : ''} ${kpiProject?.plazoDeExplotacion ? t( 'meses' ) : ''}`}
          subtitle={t( 'Plazo de explotación' )}
        />
        <LightTile
          icon={icon23}
          title={( <MillionNumeral number={kpiProject?.noiTotal} /> )}
          subtitle={t( 'NOI total' )}
        />
      </div>

      <Row>
        <Col xs={{ span: 12, order: 5 }} md={{ span: 12, order: 5 }} xl={{ span: 6, order: 4 }} className="mt-0">
          <Documents dataObjs={documents} title={`Documentos ${kpiProject?.namePromocion}`} />
        </Col>
        <Col xs={{ span: 12, order: 5 }} md={{ span: 12, order: 5 }} xl={{ span: 6, order: 4 }} className="mt-4 mt-lg-0">
          <Documents dataObjs={documentsInversion} title="Documentación contractual" />
        </Col>

      </Row>

    </BodyContent>
  );
};

export default Funds;
