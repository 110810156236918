import React, { useCallback, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  ADMIN_ACCOUNTS, ADMIN_INVESTORS, ADMIN_PROJECTS, DASHBOARD,
} from 'constants/routes';
import {
  Button, Col, Container, Nav, Row,
} from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Storage from 'utils/Storage';
import { updateUserAccount, updateUserRole, updateUserToken } from 'store/User';
import handlePromise from 'utils/Promise';
import AuthService from 'services/Auth';
import ProfileService from 'services/Profile';
import { useDispatch } from 'react-redux';
import InvestmentAccountService from 'services/InvestmentAccount';
import { ModalErrorContext } from 'context/ModalError';
import { LoadingContext } from 'context/Loading';

const TabsHeader = ( {
  title, type, id, credentialState, extraData = null,
}:{
  type?:string;
  title?:string;
  id?:number;
  credentialState?:number;
  extraData?: any
} ) => {
  const { toggleLoading } = useContext( LoadingContext );
  const { openErrorModal } = useContext( ModalErrorContext );
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const selectAccount = useCallback( ( accountId: string ) => {
    dispatch( updateUserAccount( accountId ) );
  }, [] );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeToUser = useCallback( async () => {
    if ( !( extraData && extraData.email ) ) {
      return false;
    }
    toggleLoading( true );
    const [userTokenError, userTokenResponse, userTokenData] = await handlePromise(
      AuthService.getTokenImpersonate( {
        email: extraData.email,
      } ),
    );

    if ( userTokenResponse.ok ) {
      Storage.clear( 'grupoimpar:token' );
      Storage.set( 'grupoimpar:token', userTokenData.token );
      dispatch( updateUserToken( userTokenData.token ) );

      const [, profileResponse, profileData] = await handlePromise( ProfileService.getProfile() );
      if ( profileData.isNew ) {
        toggleLoading( false );
        history.push( '/force-change-password' );
        return;
      }
      const [, accountsResponse, accountsData] = await handlePromise(
        InvestmentAccountService.getInvestmentAccounts(),
      );
      if ( profileResponse.ok ) {
        dispatch( updateUserRole( profileData?.role ) );
        if ( accountsResponse.ok && accountsData.length ) {
          if ( profileData?.role === 'Admin' && !accountsData?.length ) {
            selectAccount( 'admin' );
            history.push( ADMIN_ACCOUNTS );
          } else if ( accountsData?.length > 0 ) {
            selectAccount( accountsData[0].cuentaDeInversionId );
            history.push( DASHBOARD );
          }
        }
      }
      toggleLoading( false );
    } else {
      openErrorModal( userTokenError.toString() );
    }
  }, [] );

  return (
    <div className="nav__subheader nav__subheader-admin">
      <Container>
        {!!type && (
        <>
          <div className="px-3 pt-1 pb-0">
            <Button variant="link" size="sm" className="text--medium p-0" onClick={() => history.goBack()}>
              <i className="icon-arrow-left text--green1 icon--xss" />
              Volver
            </Button>
          </div>
          <div className="px-3 pt-3 pb-1">
            <h4 className="text--xlg text--medium">{title}</h4>
          </div>
        </>
        )}

        <Row>
          <Col>
            <Nav variant="tabs">
              {type === 'inv' && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => { history.replace( `/admin/accounts/${id}/projects/${type}`, { nameCuentadeInversion: title } ); }}
                      eventKey="accounts-inv"
                      disabled={location.pathname === `/admin/accounts/${id}/projects/inv`}
                      active={location.pathname === `/admin/accounts/${id}/projects/inv`}
                    >
                      Proyectos invertidos
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => { history.replace( `/admin/accounts/${id}/investors/${type}`, { nameCuentadeInversion: title } ); }}
                      eventKey="accounts-inv"
                      disabled={location.pathname === `/admin/accounts/${id}/investors/inv`}
                      active={location.pathname === `/admin/accounts/${id}/investors/inv`}
                    >
                      Inversores
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
              {type === 'pro' && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => { history.replace( `/admin/projects/${id}/accounts/${type}`, { codigoPromocion: title } ); }}
                      eventKey="projects-inv"
                      disabled={location.pathname === `/admin/projects/${id}/accounts/pro`}
                      active={location.pathname === `/admin/projects/${id}/accounts/pro`}
                    >
                      Cuentas de inversión
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => { history.replace( `/admin/projects/${id}/investors/${type}`, { codigoPromocion: title } ); }}
                      eventKey="projects-inv"
                      disabled={location.pathname === `/admin/projects/${id}/investors/pro`}
                      active={location.pathname === `/admin/projects/${id}/investors/pro`}
                    >
                      Inversores
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
              {type === 'user' && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => { history.replace( `/admin/investors/${id}/accounts/${type}`, { fullName: title, credentialState, extraData } ); }}
                      eventKey="investors-inv"
                      disabled={location.pathname === `/admin/investors/${id}/accounts/user`}
                      active={location.pathname === `/admin/investors/${id}/accounts/user`}
                    >
                      Cuentas de inversión
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => { history.replace( `/admin/investors/${id}/projects/${type}`, { fullName: title, credentialState, extraData } ); }}
                      eventKey="investors-inv"
                      disabled={location.pathname === `/admin/investors/${id}/projects/user`}
                      active={location.pathname === `/admin/investors/${id}/projects/user`}
                    >
                      Proyectos invertidos
                    </Nav.Link>
                  </Nav.Item>
                  {!!credentialState && credentialState >= 0 && (
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => { history.replace( `/admin/investors/${id}/change-password/user`, { fullName: title, credentialState, extraData } ); }}
                        eventKey="investors-inv"
                        disabled={location.pathname === `/admin/investors/${id}/change-password/user`}
                        active={location.pathname === `/admin/investors/${id}/change-password/user`}
                      >
                        Cambiar contraseña
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </>
              )}
              {!type && (
                <>
                  <Nav.Item>
                    <Nav.Link as={NavLink} to={ADMIN_ACCOUNTS} exact>
                      Cuentas de inversión
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as={NavLink} to={ADMIN_INVESTORS}>
                      Inversores
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as={NavLink} to={ADMIN_PROJECTS}>Proyectos</Nav.Link>
                  </Nav.Item>
                </>
              )}
            </Nav>
          </Col>
          <Col md="auto" className="align-items-center d-flex">
            {type === 'user' && !!credentialState && credentialState >= 0
            && location.pathname === `/admin/investors/${id}/change-password/user` && (
            <Button
              className="pt-1 pb-1 ps-4 pe-4 text--normal btn--rounded d-flex align-items-center"
              onClick={() => changeToUser()}
            >
              {t( 'seeUser' )}
            </Button>
            )}
          </Col>
        </Row>

      </Container>
    </div>
  );
};

export default TabsHeader;
