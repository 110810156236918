import React, { ReactNode } from 'react';
import { Form, FormProps } from 'react-final-form';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

type FormLayoutProps = { body: ReactNode, footer?: ReactNode };

export default React.memo( ( {
  body, footer, validate, onSubmit, initialValues,
}: FormProps & FormLayoutProps ) => {
  const { t } = useTranslation();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      render={( {
        handleSubmit, submitErrors, form,
      } ) => (
        <form onSubmit={handleSubmit} noValidate>
          {body && _.isFunction( body ) ? body( form ) : body}

          {_.isString( submitErrors )
                    && <div className="invalid-feedback d-block my-1 text-center">{t( submitErrors.toString() )}</div>}

          {footer && _.isFunction( footer ) ? footer( form ) : footer}
        </form>
      )}
    />
  );
} );
