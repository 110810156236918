import React, { useCallback, useEffect, useState } from 'react';
import HistoricView from 'views/User/Historic';
import handlePromise from 'utils/Promise';
import ProjectService from 'services/Projects';
import { KpiProject } from 'types/Project';
import { useSelector } from 'react-redux';
import useFetchTableData from 'hooks/useFetchTableData';
import { selectUserAccount } from 'store/User';

const Historic = () => {
  const userAccountId = useSelector( selectUserAccount );
  const [kpiProject, setKpiProject] = useState<KpiProject | null>( null );

  const { fetchData, data, dataLoading } = useFetchTableData( {
    promise: ( params ) => ProjectService.getHistorical( userAccountId, params ),
    deps: [userAccountId],
  } );

  const getData = useCallback( async () => {
    const [, kpiProjectResponse, kpiProjectData] = await handlePromise(
      ProjectService.getKpiHistorical( userAccountId ),
    );
    if ( kpiProjectResponse.ok ) setKpiProject( kpiProjectData );
  }, [userAccountId] );

  useEffect( () => {
    getData();
  }, [userAccountId] );

  return (
    <HistoricView
      kpiProject={kpiProject}
      projects={data}
      fetchData={fetchData}
      isLoading={dataLoading}
    />
  );
};
export default Historic;
