import React from 'react';

type NoDataTextProps = {
  text: string;
};
const NoDataText = ( { text }: NoDataTextProps ) => (
  <p className="text--gray8 text-center text--sm p-3 pt-5">{text}</p>
);

export default NoDataText;
