import React, { ElementType, ReactNode } from 'react';
import { Field } from 'react-final-form';
import TextField from '../TextField';

type TextFieldProps = {
  field?: string;
  value?: string;
  validate?: any;
  label?: string | ReactNode;
  translateLabel?: boolean;
  placeholder?: string;
  as?: ElementType<any>;
  rows?: number;
  type?: string;
  size?: string;
  validateTranslateValues?: any;
  style?: any;
  appendIcon?: string;
  classNames?: {
    input?: string;
    formGroup?: string;
    label?: string;
  };
  onChange?: ( value: string ) => void
  showErrors?: boolean;
  disabled?: boolean;
  onClickAppendIcon?: () => void
};

const FormTextField = ( {
  as,
  rows,
  field,
  value,
  validate,
  label,
  placeholder,
  type,
  size,
  translateLabel = true,
  validateTranslateValues,
  classNames = {},
  onChange,
  showErrors = true,
  appendIcon,
  onClickAppendIcon,
  disabled,
  style,
}: TextFieldProps ) => {
  if ( field ) {
    return (
      <Field name={field} validate={validate}>
        {( { meta, input } ) => {
          const isInvalid = ( meta.touched && !!meta.error )
            || ( meta.touched && !meta.dirtySinceLastSubmit && !!meta.submitError );

          return (
            <TextField
              value={input.value}
              onChange={onChange}
              inputOnChange={input.onChange}
              label={label}
              translateLabel={translateLabel}
              placeholder={placeholder}
              type={type}
              as={as}
              rows={rows}
              size={size}
              validateTranslateValues={validateTranslateValues}
              classNames={classNames}
              showErrors={showErrors}
              appendIcon={appendIcon}
              isInvalid={isInvalid}
              meta={meta}
              disabled={disabled}
              style={style}
              onClickAppendIcon={onClickAppendIcon}
            />
          );
        }}
      </Field>
    );
  }

  return (
    <TextField
      value={value as string}
      onChange={onChange}
      label={label}
      translateLabel={translateLabel}
      placeholder={placeholder}
      type={type}
      size={size}
      validateTranslateValues={validateTranslateValues}
      classNames={classNames}
      showErrors={showErrors}
      appendIcon={appendIcon}
      onClickAppendIcon={onClickAppendIcon}
    />
  );
};

export default FormTextField;
