import { useCallback, useContext } from 'react';

import handlePromise from 'utils/Promise';
import { LoadingContext } from 'context/Loading';

type HookProps = {
  promise: ( data: any ) => Promise<any>;
  deps?: any[],
  callback?: ( formData: any, responseData: any ) => void,
  callbackError?: ( errors: any ) => void,
  format?: ( data: any ) => void,
  showAlert?: boolean
};

export default ( {
  promise, callback, callbackError, format, deps = [], showAlert = true,
}: HookProps ) => {
  const { toggleLoading } = useContext( LoadingContext );

  return useCallback( async ( formData ) => {
    toggleLoading( true );

    const dataToSend = format ? format( formData ) : formData;
    const [errors, response, responseData] = await handlePromise( promise( dataToSend ) );
    toggleLoading( false );

    if ( callbackError && errors ) callbackError( errors );
    if ( !response.ok ) return errors;
    if ( showAlert ) {
      // alert.success( t( 'dataSaved' ) );
    }
    if ( callback ) callback( formData, responseData );
  }, [...deps]);//eslint-disable-line
};
