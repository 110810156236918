import React, {
  createContext, ReactNode, useCallback, useState,
} from 'react';

export const LoadingContext = createContext( {
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleLoading: ( loading: boolean ) => {},
} );

const LoadingProvider = ( { children }: { children: ReactNode } ) => {
  const [loading, setLoading] = useState( false );

  const toggleLoading = useCallback( ( isLoading ) => {
    setLoading( isLoading );
  }, [] );

  return (
    <LoadingContext.Provider value={{
      loading,
      toggleLoading,
    }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
