import React, {
  ReactElement,
  ReactNode, useEffect, useMemo, useState,
} from 'react';
import {
  useTable, usePagination, useSortBy, useFilters,
} from 'react-table';
import { Table } from 'react-bootstrap';
import classnames from 'classnames';
import CardLight from 'components/CardLight';
import { useTranslation } from 'react-i18next';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import Pagination from '../Pagination';

type TableProps = {
  columns: any[],
  data: any[],
  defaultSortBy?: any[],
  defaultPageSize?: number,
  total?: number,
  pageCount?: number,
  cardHeader?: ReactNode,
  isLoading?: boolean,
  paginationOptions?: string[]
  specialText?: ReactElement | string
  specialText2?: ReactElement | string
  fetchData: ( params: any, pageSize:number ) => void,
  header?: ( filters: any, setFilters: ( fields: string[], value: any ) => void ) => ReactNode
};

const RTable = ( {
  columns, data, defaultPageSize = 10, fetchData, cardHeader, specialText, specialText2,
  header, isLoading, pageCount, defaultSortBy = [],
  paginationOptions = ['10', '20', '30'],
}: TableProps ) => {
  const { t } = useTranslation();
  const [filters, setFilterDefault] = useState<any>( {} );

  const tableInstance = useTable( {
    columns: useMemo( () => columns, [columns] ),
    data: useMemo( () => data, [data] ),
    initialState: { pageSize: defaultPageSize, sortBy: defaultSortBy },
    pageCount,
    manualFilters: true,
    manualPagination: true,
    manualSortBy: true,
  }, useFilters, useSortBy, usePagination );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, sortBy, pageSize },
  } = tableInstance;

  const setFilter = ( fields: string[], value: any ) => {
    if ( pageIndex > 0 ) gotoPage( 0 );
    let query: any = {};
    fields.forEach( ( item ) => {
      query = { ...query, [item]: value };
    } );
    setFilterDefault( { ...filters, ...query } );
  };

  useEffect( () => {
    const apiParams: any = {
      Page: pageIndex + 1,
      ItemsPerPage: pageSize,
    };
    if ( sortBy && sortBy.length ) {
      apiParams.orderBy = `${sortBy[0].id}`;
      apiParams.sortDirection = `${sortBy[0].desc ? 'Descending' : 'Ascending'}`;
    }

    if ( filters ) {
      const resultKeys:any[] = [];
      Object.keys( filters ).forEach( ( key: string ) => {
        if ( filters[key] ) {
          resultKeys.push( `${key}##${filters[key]}` );
        }
      } );
      if ( resultKeys.length ) apiParams.filter = resultKeys.join( '@@' );
    }
    fetchData( apiParams, pageSize );
  }, [fetchData, pageSize, pageIndex, sortBy, filters] );

  return (
    <div className="position-relative">
      <div className={classnames( 'divTable--block', {
        'app-component-loading': isLoading,
      } )}
      >
        <div className="mb-4">
          {!!header && header( filters, setFilter )}
        </div>

        <CardLight>
          {cardHeader}
          <div className="table-responsive">
            <Table {...getTableProps()} className="table--admin">
              <thead>
                {headerGroups.map( ( headerGroup ) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map( ( column ) => (
                      <th
                        {...column.getHeaderProps( column.getSortByToggleProps() )}
                        className={column?.headerClassName}
                      >
                        {column.render( 'Header' )}
                        {!column.disableSortBy && !column.isSorted && (
                        <span className="" style={{ marginLeft: 5 }}>
                          <FaSortUp className="text--gray2" />
                          <FaSortDown className="text--gray2" style={{ marginLeft: -14 }} />
                        </span>
                        )}
                        {!column.disableSortBy && column.isSorted && !column.isSortedDesc && (
                        <span className="" style={{ marginLeft: 5 }}>
                          <FaSortUp className="" />
                          <FaSortDown className="text--gray2" style={{ marginLeft: -14 }} />
                        </span>
                        )}
                        {!column.disableSortBy && column.isSorted && column.isSortedDesc && (
                        <span className="" style={{ marginLeft: 5 }}>
                          <FaSortUp className="text--gray2" />
                          <FaSortDown className="" style={{ marginLeft: -14 }} />
                        </span>
                        )}

                      </th>
                    ) )}
                  </tr>
                ) )}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length ? page.map( ( row ) => {
                  prepareRow( row );
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map( ( cell ) => {
                        const style: any = {};
                        if ( cell.column.customWidth ) {
                          style.maxWidth = cell.column.customWidth;
                          style.width = cell.column.customWidth;
                        }
                        return (
                          <td
                            style={style}
                            {...cell.getCellProps()}
                            className={cell.column?.className}
                          >
                            {cell.render( 'Cell' )}
                          </td>
                        );
                      } )}
                    </tr>
                  );
                } ) : (
                  <tr>
                    <td colSpan={100} className="text-center">
                      {t( 'noTableData' )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {!!specialText && (
          <p className="text--xxs text--gray8 m-0 text--lato mb-2">{specialText}</p>
          )}

        </CardLight>

        <Pagination
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          pageCount={pageCount}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          options={paginationOptions}
        />
      </div>
      {!!specialText2 && (
      <p className="text--xxs text--gray8 m-0 text--lato mb-2 ms-2">{specialText2}</p>
      )}
    </div>
  );
};

export default RTable;
